import {
	Box, Card, CardActions, CardContent, CardHeader,
	Divider, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography
} from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import {
	AccountTree as AccountTreeIcon, ArrowBackRounded as ArrowBackRoundedIcon,
	Assessment as AssessmentIcon, Check as CheckIcon, Launch as LaunchIcon,
	Clear as ClearIcon, FormatListNumbered as FormatListNumberedIcon, KeyboardArrowDown as KeyboardArrowDownIcon,
	KeyboardArrowUp as KeyboardArrowUpIcon, ShowChart as ShowChartIcon
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import Tile from 'components/reports/Tile';
import fromNow from 'fromnow';
import MaterialTable from 'material-table';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from "react-router-dom";
import { get, post, del } from './components/commons/common-components';
import GroupIcon from '@material-ui/icons/Group';
import TuneIcon from '@material-ui/icons/Tune';

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(2)
	},
	table: {
		minWidth: 250,
	},
}))

const ProjectsPage = props => {
	const classes = useStyles();
	const token = useSelector(state => state.user.token);
	const [data, setData] = useState([]);
	const history = useHistory();
	useEffect(() => {
		init();
	}, [])

	async function onRowAdd(row) {
		await post(`/api/projects`, token, row);
		init();
	}

	async function onRowUpdate(newRow, oldRow) {
		await post(`/api/projects`, token, newRow);
		init();
	}

	async function onRowDelete(oldRow) {
		await del(`/api/projects/${oldRow._id}`, token, oldRow);
		init();
	}

	async function init() {
		let rows = await get(`/api/projects`, token);
		setData(rows);
	}

	return (
		<div className={classes.root}>
			<Card>
				<CardHeader title={'Projects'} />
				<Divider />
				<CardContent>

					<MaterialTable
						title=""
						columns={[
							{ title: 'Id', field: '_id', hidden: true },
							{ title: 'Name', field: 'name' },
							{ title: 'Description', field: 'description' },
							{
								title: "Users", field: "users", editable: 'never',
								render: (rowData) => (
									<Tooltip title={`${rowData?.users?.length || 0} users`}>
										<GroupIcon color='action' style={{ cursor: "pointer" }} onClick={() => history.push(`/projects/${rowData._id}/users`)} />
									</Tooltip>
								),
							},
							{
								title: "Variables", field: "variables", editable: 'never',
								render: (rowData) => (
									<Tooltip title={`Configure project level global variables`}>
										<TuneIcon color='action' style={{ cursor: "pointer" }} onClick={() => history.push(`/projects/${rowData._id}/variables`)} />
									</Tooltip>
								),
							},
							// { title: 'Status', field: 'success' },
						]}
						data={data}
						options={{
							pageSize: 10
							//selection: true
						}}

						// onRowClick={(evt, selectedRow) => {
						// 	history.push(`/projects/${selectedRow._id}/users`)
						// }}
						editable={{
							onRowAdd: onRowAdd,
							onRowUpdate: onRowUpdate,
							onRowDelete: onRowDelete
						}}
					/>
				</CardContent>
				<Divider />
				<CardActions >
				</CardActions>
			</Card>
		</div >
	)

}

export default ProjectsPage;
