import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  Grid,
  Button,
  IconButton,
  TextField,
  Link,
  FormHelperText,
  Checkbox,
  Typography
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { connect } from 'react-redux';
//import { loggedIn } from '../../actions/userActions';
import ReCAPTCHA from "react-google-recaptcha";

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const schema = {
  name: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 32
    }
  },
  company: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 32
    }
  },
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64
    }
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128
    }
  },
  policy: {
    presence: { allowEmpty: false, message: 'is required' },
    checked: true
  }
};

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  grid: {
    height: '100%'
  },
  quoteContainer: {
    backgroundColor: 'white',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    //backgroundImage: 'url(/images/auth.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px'
  },
  quoteText: {
    color: theme.palette.black,
    fontWeight: 300
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.black
  },
  bio: {
    color: theme.palette.black
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  policy: {
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center'
  },
  policyCheckbox: {
    marginLeft: '-14px'
  },
  signUpButton: {
    margin: theme.spacing(2, 0)
  }
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class SignUp extends React.Component {

  state = {
    isValid: false,
    recaptchaValue: null,
    values: {},
    touched: {},
    errors: {},
    alerts: {
      signUpSuccess: false
    }
  }

  handleBack = () => e => {
    this.props.history.goBack();
  };

  handleSignUp = async event => {
    event.preventDefault();

    let res = await fetch('/api/users/signup', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        recaptchaValue: this.state.recaptchaValue,
        ...this.state.values,
      })
    });
    let json = await res.json();
    if (json && json.status === 'success') {
      //alert('User successfully created');
      this.setState({
        alerts: {
          ...this.state.alerts,
          signUpSuccess: true
        }
      })
      setTimeout(() => {
        this.props.history.push('/sign-in');
      }, 3000);
    } else {
      alert(json.message);
    }
    //this.props.history.push('/');
  };

  hasError = field => {
    return this.state.touched[field] && this.state.errors[field] ? true : false;
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({
      alerts: {
        ...this.state.alerts,
        signUpSuccess: false
      }
    })
  };

  captchaOnChange = (v) => {
    this.setState({ recaptchaValue: v });
  }


  handleChange = event => {
    event.persist();
    this.setState({
      values: {
        ...this.state.values,
        [event.target.name]: event.target.type === 'checkbox' ? event.target.checked : event.target.value,
      },
      touched: {
        ...this.state.touched,
        [event.target.name]: true
      }
    }, () => {

      const errors = validate(this.state.values, schema);
      this.setState({
        isValid: errors ? false : true,
        errors: errors || {}
      })
    })
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        <div className={classes.root}>
          <Grid
            className={classes.grid}
            container
          >
            <Grid
              className={classes.quoteContainer}
              item
              lg={5}
            >
              <div className={classes.quote}>
                <div className={classes.quoteInner}>
                  <Typography
                    className={classes.quoteText}
                    variant="h1"
                  >
                    Speed up your automation by 25%
                </Typography>
                  <div className={classes.person}>
                    <Typography
                      className={classes.name}
                      variant="body1"
                    >
                      Maintain your testcases like never before, projects, access control and much more.
                  </Typography>

                  </div>
                </div>
              </div>
            </Grid>
            <Grid
              className={classes.content}
              item
              lg={7}
              xs={12}
            >
              <div className={classes.content}>
                <div className={classes.contentHeader}>
                  <IconButton onClick={() => this.props.history.push(`/sign-in`)}>
                    <ArrowBackIcon />
                  </IconButton>
                </div>
                <div className={classes.contentBody}>
                  <form
                    className={classes.form}
                    onSubmit={this.handleSignUp}
                  >
                    <Typography
                      className={classes.title}
                      variant="h2"
                    >
                      Create new account
                  </Typography>
                    <Typography
                      color="textSecondary"
                      gutterBottom
                    >
                      Use your email to create new account
                  </Typography>
                    <TextField
                      className={classes.textField}
                      error={this.hasError('name')}
                      fullWidth
                      helperText={
                        this.hasError('name') ? this.state.errors.name[0] : null
                      }
                      label="Name"
                      name="name"
                      onChange={this.handleChange}
                      type="text"
                      value={this.state.values.name || ''}
                      variant="outlined"
                    />
                    <TextField
                      className={classes.textField}
                      error={this.hasError('company')}
                      fullWidth
                      helperText={
                        this.hasError('company') ? this.state.errors.company[0] : null
                      }
                      label="Company"
                      name="company"
                      onChange={this.handleChange}
                      type="text"
                      value={this.state.values.company || ''}
                      variant="outlined"
                    />
                    <TextField
                      className={classes.textField}
                      error={this.hasError('email')}
                      fullWidth
                      helperText={
                        this.hasError('email') ? this.state.errors.email[0] : null
                      }
                      label="Email address"
                      name="email"
                      onChange={this.handleChange}
                      type="text"
                      value={this.state.values.email || ''}
                      variant="outlined"
                    />
                    <TextField
                      className={classes.textField}
                      error={this.hasError('password')}
                      fullWidth
                      helperText={
                        this.hasError('password') ? this.state.errors.password[0] : null
                      }
                      label="Password"
                      name="password"
                      onChange={this.handleChange}
                      type="password"
                      value={this.state.values.password || ''}
                      variant="outlined"
                    />
                    <br /><br />
                    <ReCAPTCHA
                      sitekey="6LeCqqwaAAAAACpQBB6VewvcIWzP7fs7OIu9Y8Ag"
                      onChange={this.captchaOnChange}
                    />
                    <div className={classes.policy}>
                      <Checkbox
                        checked={this.state.values.policy || false}
                        className={classes.policyCheckbox}
                        color="primary"
                        name="policy"
                        onChange={this.handleChange}
                      />

                      <Typography
                        className={classes.policyText}
                        color="textSecondary"
                        variant="body1"
                      >
                        I have read the{' '}
                        <Link
                          color="primary"
                          component={RouterLink}
                          to="terms-and-conditions"
                          underline="always"
                          variant="h6"
                        >
                          Terms and Conditions
                      </Link>
                      </Typography>
                    </div>
                    {this.hasError('policy') && (
                      <FormHelperText error>
                        {this.state.errors.policy[0]}
                      </FormHelperText>
                    )}
                    <Button
                      className={classes.signUpButton}
                      color="primary"
                      disabled={!this.state.isValid || !this.state.recaptchaValue}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Sign up now
                  </Button>
                    <Typography
                      color="textSecondary"
                      variant="body1"
                    >
                      Have an account?{' '}
                      <Link
                        component={RouterLink}
                        to="/sign-in"
                        variant="h6"
                      >
                        Sign in
                    </Link>
                    </Typography>
                  </form>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>


        <Snackbar open={this.state.alerts.signUpSuccess} autoHideDuration={6000} onClose={this.handleClose}>
          <Alert onClose={this.handleClose} severity="success">
            Signup successful. Please login. We will automatically redirect to sign in page in few seconds.
        </Alert>
        </Snackbar>

      </>
    );
  }

}


SignUp.propTypes = {
  history: PropTypes.object
};

const mapStateToProps = state => ({
  version: state.user.version
});

export default connect(mapStateToProps, {})(withStyles(styles)(SignUp));


