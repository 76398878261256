import {
	Button, Divider,
	FormControl, FormControlLabel, Grid, IconButton, Paper, Radio, RadioGroup, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography
} from '@material-ui/core';
import {
	Delete as DeleteIcon, KeyboardArrowDown as KeyboardArrowDownIcon,
	KeyboardArrowUp as KeyboardArrowUpIcon
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { IterationBody, IterationResponse, IterationValues, JsonBody, JsonResponse, TaskDefinition, VariablesComponent, TaskDefinitionUI, IterationValuesUI, IterationBodyUI } from './ManageTestsDataPageComponents';
import { get, post } from './components/commons/common-components';
var _ = require('lodash');


let DEBUG_MODE = false;

const useStyles = makeStyles((theme) => ({
	tabs: {
		paddingLeft: 25
	},
	addButton: {
		marginRight: theme.spacing(1.5)
	},
	mt: {
		margin: theme.spacing(1)
	},
	table: {
		minWidth: 650,
	},
	sticky: {
		position: "sticky",
		width: 300,
		left: 0,
		background: "white",
		boxShadow: "5px 2px 5px #f1f4f5",
		zIndex: 10
	},
	nonSticky: {
		width: 300,
		overflowX: "auto",
	},
}))

const ManageUITestsDataPage = props => {
	const classes = useStyles();
	const params = useParams();
	const token = useSelector(state => state.user.token);
	const projectId = useSelector(state => state.user.project?._id);
	const [test, setTest] = useState({});
	const [stage, setStage] = useState(props.test.stage);
	const [domain, setDomain] = useState('');
	const [taskInstanceIdVsIterations, setTaskInstanceIdVsIterations] = useState({});
	const [taskInstanceIdVsTask, setTaskInstanceIdVsTask] = useState({});
	const [taskInstanceIdVsOpen, setTaskInstanceIdVsOpen] = useState({});
	const [taskInstanceDataVsOpen, setTaskInstanceDataVsOpen] = useState({});
	const [testVariables, setTestVariables] = useState([]);
	const [projectData, setProjectData] = useState(props.projectData);

	useEffect(() => {
		fetchTest(props.test);
		// getProjectData();
	}, [])

	// async function getProjectData() {
	// 	console.log('getProjectData for' + projectId);
	// 	let res = await fetch(`/api/projects/${projectId}`, { headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${token}` }, });
	// 	let json = await res.json();
	// 	console.log('json', json);
	// 	setProjectData(json);
	// }

	async function fetchTest(test) {
		let tasks = await post(`/api/tests/getTasks`, token, { ids: test.tasks.map(ele => ele.taskId) });
		let newTaskIdVsTask = {}
		let newTaskInstanceIdVsTask = {}
		if (tasks) {
			for (let task of tasks) {
				newTaskIdVsTask[task._id] = task;
			}
			for (let task of test.tasks) {
				newTaskInstanceIdVsTask[task.taskInstanceId] = newTaskIdVsTask[task.taskId]
			}
			setTaskInstanceIdVsTask({ ...newTaskInstanceIdVsTask });
		}
		const variables = test.variables ? test.variables : [];
		setTestVariables(variables);
		// updateVariablesColumns(variables);
		delete test.variables;
		setTest(test);
		// if (test.stage) { setStage(test.stage); }
		if (test.domain) { setDomain(test.domain); }
		if (test.taskInstanceIdVsIterations) {
			let _taskInstanceIdVsIterations = _.cloneDeep(test.taskInstanceIdVsIterations);
			for (let stg of projectData.environments) {
				if (_taskInstanceIdVsIterations[stg]) {
					// Fix iterations, add missing tasks info
					let noOfIterations = Object.values(_taskInstanceIdVsIterations[stg])[0] ? Object.values(_taskInstanceIdVsIterations[stg])[0].length : 0
					for (let t of test.tasks) {
						if (!_taskInstanceIdVsIterations[stg][t.taskInstanceId]) {
							console.log('when does this happens?');
							let task = newTaskIdVsTask[t.taskId];
							console.log(task, 'task');
							let newIterationsData = [];
							for (let i = 0; i < noOfIterations; i++) {
								newIterationsData.push({
									"headers": _.cloneDeep(task.headers),
									"queryParameters": _.cloneDeep(task.queryParameters),
									"formParameters": _.cloneDeep(task.formParameters),
									"body": task.body,
									"response": ""
								})
							}
							_taskInstanceIdVsIterations[stg][t.taskInstanceId] = newIterationsData;
						}
					}
				}
			}
			setTaskInstanceIdVsIterations(_taskInstanceIdVsIterations);
		} else { // when comes for the first time....
			addIteration(newTaskInstanceIdVsTask);
		}
	}

	function handleAddIteration() {
		addIteration(taskInstanceIdVsTask);
	}

	function getTaskIterationCopy(task) {
		let actionVsData = {}
		for (let action of task.actions) {
			actionVsData[`id_${action.tableData.id}`] = (action.dataElement || []).reduce((obj, key) => ({ ...obj, [key]: "" }), {});
		}
		return actionVsData;
	}

	function addIteration(taskInstanceIdVsTask) {
		console.log('addIteration');
		let newtaskInstanceIdVsIterations = { ...taskInstanceIdVsIterations };
		console.log('taskInstanceIdVsTask', taskInstanceIdVsTask);
		for (const [taskInstanceId, task] of Object.entries(taskInstanceIdVsTask)) {
			// do at stage level
			if (!newtaskInstanceIdVsIterations[stage]) {
				newtaskInstanceIdVsIterations = { ...newtaskInstanceIdVsIterations, [stage]: {} }
			}

			let n = getTaskIterationCopy(task);

			if (newtaskInstanceIdVsIterations[stage][taskInstanceId] && newtaskInstanceIdVsIterations[stage][taskInstanceId].length > 0) {
				let x = newtaskInstanceIdVsIterations[stage][taskInstanceId][newtaskInstanceIdVsIterations[stage][taskInstanceId].length - 1];
				n = _.cloneDeep(x);
			}

			newtaskInstanceIdVsIterations[stage][taskInstanceId] = [
				...newtaskInstanceIdVsIterations[stage][taskInstanceId] || [],
				n
			]
		}
		console.log('newtaskInstanceIdVsIterations123', newtaskInstanceIdVsIterations);
		setTaskInstanceIdVsIterations({ ...newtaskInstanceIdVsIterations })
	}

	function removeIteration(index) {
		let newtaskInstanceIdVsIterationsOfCurrentStage = { ...taskInstanceIdVsIterations[stage] };
		for (let [key, value] of Object.entries(newtaskInstanceIdVsIterationsOfCurrentStage)) {
			value.splice(index, 1);
		}
		// Object.values(taskInstanceIdVsTask).map(taskInstanceId => {
		// 	newtaskInstanceIdVsIterations[taskInstanceId].splice(index, 1);
		// })
		setTaskInstanceIdVsIterations({
			...taskInstanceIdVsIterations,
			[stage]: newtaskInstanceIdVsIterationsOfCurrentStage
		})
	}

	function updateIterationValues(taskInstanceId, iterationIndex, type, valueIndex, value) {
		let newIterations = [...taskInstanceIdVsIterations[taskInstanceId]]
		if (newIterations[iterationIndex][type] && newIterations[iterationIndex][type].length > 0) {
			let headers = newIterations[iterationIndex][type];
			headers.forEach((map, mapIndex) => {
				if (mapIndex === valueIndex) {
					map.value = value;
				}
			})
		}
		setTaskInstanceIdVsIterations({ ...taskInstanceIdVsIterations, [taskInstanceId]: newIterations })
	}

	function updateIterationBody(taskInstanceId, iterationIndex, type, value, dEleId) {
		let newIterations = [...taskInstanceIdVsIterations[stage][taskInstanceId]]
		if (!newIterations[iterationIndex][dEleId]) {
			newIterations[iterationIndex][dEleId] = {};
		}
		newIterations[iterationIndex][dEleId][type] = value;
		setTaskInstanceIdVsIterations({
			...taskInstanceIdVsIterations,
			[stage]: {
				...taskInstanceIdVsIterations[stage],
				[taskInstanceId]: newIterations
			}
		})
	}

	async function handleSave() {
		let json = await post(`/api/tests/`, token, { ...test, stage, domain, taskInstanceIdVsIterations: taskInstanceIdVsIterations, variables: testVariables })
		if (json.status === 'success') {
			alert('Saved!');
		} else {
			alert(json.message || 'Something went wrong!');
		}
	}

	async function handleTest() {
		// save the current iterations
		await post(`/api/tests/`, token, { ...test, stage, domain, taskInstanceIdVsIterations: taskInstanceIdVsIterations, variables: testVariables })
		// trigger test
		let json = await get(`/api/tasks/testTasks/ui/${test._id}?stage=${stage}`, token)
		if (json.status === 'success') {
			alert(json.message || `Test successful`);
		} else {
			alert('Test Failed!');
		}
	}

	let noOfIterations = (taskInstanceIdVsIterations && taskInstanceIdVsIterations[stage] && Object.values(taskInstanceIdVsIterations[stage])) ?
		Object.values(taskInstanceIdVsIterations[stage])[0].length
		:
		0;

	return (
		<div >
			<Grid container direction="row"> <VariablesComponent classes={classes} testVariables={testVariables} setTestVariables={setTestVariables} /> </Grid>
			<br />
			<Grid container direction="row" justify="space-between" alignItems="flex-start">
				<Grid item>
					<FormControl component="fieldset">
						<RadioGroup row aria-label="position" name="position" value={stage} onChange={e => setStage(e.target.value)} >
							{projectData?.environments?.map((env, index) => <FormControlLabel key={env} value={env} control={<Radio color="primary" />} label={env} labelPlacement="end" />)}
						</RadioGroup>
					</FormControl>
				</Grid>
				<Grid item>
					<Button className={classes.addButton} color="primary" size="small" variant="outlined" onClick={() => handleAddIteration()}> Add Iteration </Button>
				</Grid>
			</Grid>

			{/* <br /><br />
			<TextField fullWidth label="Domain" margin="dense" name="domain" onChange={e => { setDomain(e.target.value) }} required value={domain} variant="outlined" /> */}
			<br /><br />
			<>
				<Grid container spacing={1}>
					<Grid item lg={12}>
						<TableContainer component={Paper}>
							<Table className={classes.table} style={{ tableLayout: "fixed" }}>
								<TableHead>
									<TableRow>
										<TableCell className={classes.sticky} style={{ backgroundColor: '#fafafa' }}> {'UI Test Iterations:'} </TableCell>
										{Array.from(Array(noOfIterations)).map((xiterationIndex, index) => (
											<TableCell className={classes.nonSticky} key={`col-Iteration${index + 1}`} align="center">
												{`Iteration-${index + 1}`} <IconButton onClick={() => removeIteration(index)}> <DeleteIcon fontSize="small" /> </IconButton>
											</TableCell>
										))}
									</TableRow>
								</TableHead>
								<TableBody>
									{Object.entries(taskInstanceIdVsTask).map(([taskInstanceId, task], indx) => (
										<React.Fragment key={`taskInstanceId${taskInstanceId}`}>
											<TableRow>
												<TableCell className={classes.sticky}>
													<div style={{ display: 'flex' }}>
														<IconButton size="small" onClick={() => setTaskInstanceIdVsOpen({ ...taskInstanceIdVsOpen, [taskInstanceId]: !(taskInstanceIdVsOpen[taskInstanceId]) })}>
															{taskInstanceIdVsOpen[taskInstanceId] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
														</IconButton>
														<Typography variant="h6" color="primary" className={'pdl10'} > {indx + 1}. {task.name}</Typography>
													</div>
												</TableCell>
												{taskInstanceIdVsIterations[stage] && taskInstanceIdVsIterations[stage][taskInstanceId] && taskInstanceIdVsIterations[stage][taskInstanceId].map((iteration, index) =>
													<TableCell className={classes.nonSticky} key={`empty-Iteration${index + 1}`} align="center">{/** Empty */}</TableCell>)}
											</TableRow>

											{taskInstanceIdVsOpen[taskInstanceId] &&
												<>
													{task?.actions?.map((action, actionIndex) => <>
														{action?.dataElement?.length > 0 && <TableRow >
															<TableCell className={classes.sticky}>
																<TaskDefinitionUI classes={classes} type={action.action + (DEBUG_MODE ? " ( Action ) " : "")} task={task}
																	data={action.dataElement}
																	isOpen={taskInstanceDataVsOpen[taskInstanceId + "_" + action.tableData.id]}
																	handleOpen={v => setTaskInstanceDataVsOpen({ ...taskInstanceDataVsOpen, [taskInstanceId + "_" + action.tableData.id]: v })} />
															</TableCell>

															{taskInstanceIdVsIterations[stage] && taskInstanceIdVsIterations[stage][taskInstanceId] && taskInstanceIdVsIterations[stage][taskInstanceId].map((iteration, index) => <>
																<TableCell className={classes.nonSticky} style={{ paddingTop: 40 }} key={`${action.tableData.id}-Iteration-${index + 1}`} align="center">
																	{action.dataElement && action.dataElement.map((dataElement, dataElementIndex) => <>
																		{taskInstanceDataVsOpen[taskInstanceId + "_" + action.tableData.id] && <>
																			<IterationBodyUI
																				label={dataElement}
																				classes={classes}
																				value={(iteration[`id_${action.tableData.id}`]) ? iteration[`id_${action.tableData.id}`][dataElement] : ''}
																				updateIterationBody={(v) => { updateIterationBody(taskInstanceId, index, dataElement, v, `id_${action.tableData.id}`) }}
																				testVariables={testVariables} />
																		</>}
																	</>)}
																</TableCell>
															</>)}
														</TableRow>}
													</>)}
												</>
											}
										</React.Fragment>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</Grid>
				</Grid>
			</>
			<br />
			<Divider />
			<div className={'pdl2'}>
				<Button color="primary" variant="contained" onClick={() => handleSave()} > Save </Button>
				<Button className={classes.mt} color="primary" variant="contained" onClick={() => handleTest()} > Test </Button>
			</div>
		</div>
	)
}

export default ManageUITestsDataPage;