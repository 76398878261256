import {
	Box, Card, CardActions, CardContent, CardHeader,
	Divider, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
	Typography
} from '@material-ui/core';
import { green, red, grey, blue } from '@material-ui/core/colors';
import {
	AccountTree as AccountTreeIcon, ArrowBackRounded as ArrowBackRoundedIcon,
	Assessment as AssessmentIcon, Check as CheckIcon,
	Clear as ClearIcon, FormatListNumbered as FormatListNumberedIcon, KeyboardArrowDown as KeyboardArrowDownIcon,
	KeyboardArrowUp as KeyboardArrowUpIcon,
	Launch as LaunchIcon,
	ShowChart as ShowChartIcon,
	HourglassEmpty as HourglassEmptyIcon,
	Info as InfoIcon,
} from '@material-ui/icons';


import { makeStyles } from '@material-ui/styles';
import Tile from 'components/reports/Tile';
import fromNow from 'fromnow';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from "react-router-dom";
import { get } from './components/commons/common-components';


const SHOW_TEST_RESULTS = false;

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(2)
	},
	table: {
		minWidth: 250,
	},
	resultIcon: {
		float: 'right',
		paddingTop: 2,
		marginRight: 2
	},
	resultIconWithLink: {
		float: 'right',
		paddingTop: 2,
		marginRight: 2,
		cursor: 'pointer'
	},
	success_Failed: {
		textDecoration: "underline",
		color: "red",
	},
	success_Success: {
		textDecoration: "underline",
		color: "green",
	}


}))

const ReportsPageUI = props => {
	const classes = useStyles();
	const token = useSelector(state => state.user.token);
	let { jobId } = useParams();
	const [tableData, settTableData] = useState([]);
	const [jobData, setJobData] = useState(null);
	const [job, setJob] = useState(null);
	const [task, setTask] = useState(null);

	const history = useHistory();

	const [iterationVsOpen, setIterationVsOpen] = useState({});
	const [rightSideData, setRightSideData] = useState({});


	useEffect(() => {
		buildHome(jobId);
	}, [jobId])

	function handleIterationOpen(x) {
		let currentState = { ...iterationVsOpen };
		currentState[x] = !currentState[x];
		setIterationVsOpen(currentState);
	}

	async function buildHome(jobId) {
		if (jobId) {
			let job = await get(`/api/tests/reports_ui/${jobId}`, token);
			// setJobData(rows);

			let iVsOData = {};
			for (let row of job.iterations) {
				let key = row.iteration;
				iVsOData[key] = false;
			}

			console.log("iVsOData", iVsOData);

			setIterationVsOpen(iVsOData);
			setJob(job);
		} else {
			history.push(`/reports`);
			history.go();
		}
	}

	function getJobStatus(job) {
		if (job.testStatus === "Completed") {
			// loop thorugh all iterations and check if all are success
			let result = 'Success';
			for (let iteration of (job.iterations || [])) {
				for (let task of (iteration.tasks || [])) {
					for (let action of (task.actions || [])) {
						for (let message of (action.messages || [])) {
							if (message.status?.toLowerCase() === 'fail') {
								result = 'Failed';
							}
						}
					}
				}
			}
			return result;
		} else {
			return job.testStatus;
		}
	}

	function getIterationStatus(iteration) {
		// possible statuses -> Success, Failed, In Progress
		let result = 'Success';
		for (let task of (iteration.tasks || [])) {
			for (let action of (task.actions || [])) {
				if (action?.messages?.length > 0) {
					for (let message of (action.messages || [])) {
						if (message.status?.toLowerCase() === 'fail') {
							result = 'Failed';
						}
					}
				} else {
					result = 'In Progress';
				}
			}
		}
		return result;
	}

	return (
		<div className={classes.root}>
			<Card>
				{job && <>
					<CardHeader
						avatar={
							<IconButton size="small" aria-label="delete" onClick={() => { buildHome() }}>
								<ArrowBackRoundedIcon color="primary" />
							</IconButton>
						}
						title={`Report - ${job.name}`}
					/>


					<Divider />
					<CardContent>
						<Grid container spacing={4}>
							<Grid item lg={3} sm={6} xl={3} xs={12} >
								<Tile
									titlename={"Test"}
									titlevalue={job.name}
									footerpercent={-1}
									footervalue={`Report ID: ${job._id} ran ${fromNow(new Date(job.date), { max: 1, suffix: true })}`}
									icon={<AssessmentIcon />}
								/>
							</Grid>
							<Grid item lg={3} sm={6} xl={3} xs={12} >
								<Tile
									titlename={"Total Iterations"}
									titlevalue={job.iterations?.length || 0}
									footerpercent={-1}
									footervalue={`Total iterations in the test`}
									icon={<FormatListNumberedIcon />}
								/>
							</Grid>
							<Grid item lg={3} sm={6} xl={3} xs={12} >
								<Tile
									titlename={"Tasks per iteration"}
									titlevalue={job.iterations?.length > 0 ? (job.iterations[0]?.tasks?.length || 0) : 0}
									footerpercent={-1}
									footervalue={`Number of tasks in single iteration`}
									icon={<AccountTreeIcon />}
								/>
							</Grid>
							<Grid item lg={3} sm={6} xl={3} xs={12} >
								<Tile
									titlename={"Total Tasks"}
									titlevalue={`${(job.iterations?.length || 0) * (job.iterations?.length > 0 ? (job.iterations[0]?.tasks?.length || 0) : 0)}`}
									footerpercent={-1}
									footervalue={`${job.iterations?.length || 0} iteration(s) X ${job.iterations?.length > 0 ? (job.iterations[0]?.tasks?.length || 0) : 0} tasks`}
									icon={<ShowChartIcon />}
								/>
							</Grid>
						</Grid>
						<br /><br />
						<Box ml={1}>
							<Grid container spacing={2} direction="row" justifyContent="space-between">
								<Grid item>
									<Typography variant="h4" gutterBottom>
										{` Status: `} <span className={classes[`success_${getJobStatus(job)}`]}>{getJobStatus(job)}</span>
									</Typography>
								</Grid>
								<Grid item>
									<Typography variant="h4" gutterBottom>
										{` Env: `} <span style={{ color: "#3f51b5" }}>{job.stage},</span>
										{` Browser: `} <span style={{ color: "#3f51b5" }}>{job.browser || 'NA'}</span>
									</Typography>
								</Grid>
							</Grid>

						</Box>
						<br />
						<Box ml={1}>
							<Typography variant="h4" gutterBottom>
								{` Test Results`}
							</Typography>
						</Box>
						<br />

						<Grid container spacing={4}>

							<Grid item xs={6}>
								<TableContainer component={Paper}>
									<Table className={classes.table} style={{ tableLayout: "fixed" }}>

										<TableHead>
											<TableRow>
												<TableCell className={classes.sticky} style={{ backgroundColor: '#fafafa' }}> {'Test Iterations'} </TableCell>
											</TableRow>
										</TableHead>
										<TableBody>

											{job.iterations.map((ele, index) =>
												<React.Fragment key={`col-Iteration${ele.iteration}`}>
													<TableRow >
														<TableCell className={classes.nonSticky} align="left">
															<IconButton size="small" onClick={() => handleIterationOpen(ele.iteration)}>
																{iterationVsOpen && iterationVsOpen[ele.iteration] === true ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
															</IconButton>
															{`${ele.iteration}`}
															{getIterationStatus(ele) === 'Success' && <CheckIcon style={{ color: green[500] }} className={classes.resultIcon} />}
															{getIterationStatus(ele) === 'Failed' && <ClearIcon style={{ color: red[500] }} className={classes.resultIcon} />}
															{getIterationStatus(ele) === 'In Progress' && <HourglassEmptyIcon style={{ color: grey[500] }} className={classes.resultIcon} />}
														</TableCell>
													</TableRow>

													{iterationVsOpen && iterationVsOpen[ele.iteration] && <>
														{(ele.tasks || []).map((taskEle, taskIndex) => <>
															<TableRow key={`${ele.iteration}_${taskEle.task}`} onClick={() => { /** TOBE */ }}>
																<TableCell className={classes.nonSticky} align="left">
																	<Box ml={6}>
																		{`${taskEle.task}`}
																	</Box>
																</TableCell>
															</TableRow>
															{taskEle.actions?.map((action, actionIndex) => <>
																<TableRow key={`${ele.iteration}_${taskEle.task}_${actionIndex}`} onClick={() => { /** TOBE */ }}>
																	<TableCell className={classes.nonSticky} align="left">
																		<Box ml={8}>
																			{`${action.action}`}
																		</Box>
																	</TableCell>
																</TableRow>

																{Object.keys(action?.dataElements || {}).length > 0 &&
																	<TableRow key={`${ele.iteration}_${taskEle.task}_${actionIndex}_dataElements`} hover style={{ cursor: 'pointer' }}
																		onClick={() => {
																			setRightSideData({
																				type: 'dataElement',
																				data: action.dataElements
																			})
																		}
																		}>
																		<TableCell className={classes.nonSticky} align="left">
																			<Box ml={10}>
																				{`Data Elements (${Object.keys(action?.dataElements || {}).length})`}
																			</Box>
																		</TableCell>
																	</TableRow>
																}
																{(action.messages || []).map((message, messageIndex) => <>
																	<TableRow key={`${ele.iteration}_${taskEle.task}_${actionIndex}_${messageIndex}`} hover style={{ cursor: 'pointer' }}
																		onClick={() => {
																			setRightSideData({
																				type: 'message',
																				data: message
																			})
																		}}>
																		<TableCell className={classes.nonSticky} align="left">
																			<Box ml={10}>
																				{`${message.description || '-'}`}
																				{message.status?.toLowerCase() === 'pass' && <CheckIcon style={{ color: green[500] }} className={classes.resultIcon} />}
																				{message.status?.toLowerCase() === 'fail' && <ClearIcon style={{ color: red[500] }} className={classes.resultIcon} />}
																				{message.status?.toLowerCase() === 'info' && <InfoIcon style={{ color: blue[500] }} className={classes.resultIcon} />}
																			</Box>
																		</TableCell>
																	</TableRow>
																</>
																)}
															</>)}
														</>)}

													</>
													}
												</React.Fragment >
											)}
										</TableBody>
									</Table>

								</TableContainer>

							</Grid>

							<Grid item xs={6}>
								<TableContainer component={Paper}>
									<Table className={classes.table} style={{ tableLayout: "fixed" }}>
										<TableHead>
											<TableRow>
												<TableCell className={classes.sticky} style={{ backgroundColor: '#fafafa' }}> {'Details'} </TableCell>
											</TableRow>
										</TableHead>

										{!(rightSideData.data) &&
											<TableBody>
												<TableRow >
													<TableCell className={classes.nonSticky} align="left">
														<Box ml={4}>
															{`Select a Data Element/ Message to see details`}
														</Box>
													</TableCell>
												</TableRow>
											</TableBody>
										}
										{rightSideData.type === 'dataElement' &&
											<TableBody>
												<TableRow >
													<TableCell className={classes.nonSticky} align="left">
														<Box ml={4}>
															{`Type: Data Elements`}
														</Box>
													</TableCell>
												</TableRow>
												{Object.keys(rightSideData.data).map((key, index) => {
													return (
														<TableRow key={`dataElement_${index}`} >
															<TableCell className={classes.nonSticky} align="left">
																<Box ml={4}>
																	{`${key}: ${rightSideData.data[key]}`}
																</Box>
															</TableCell>
														</TableRow>
													)
												})}
											</TableBody>
										}
										{rightSideData.type === 'message' &&
											<>
												<TableBody>
													<TableRow >
														<TableCell className={classes.nonSticky} align="left">
															<Box ml={4}>
																{`Message: ${rightSideData.data.description}, Status: ${rightSideData.data.status}`}
															</Box>
														</TableCell>
													</TableRow>
												</TableBody>
												<TableHead>
													<TableRow>
														<TableCell className={classes.sticky} style={{ backgroundColor: '#fafafa' }}> {'Expected:  '} </TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													<TableRow >
														<TableCell className={classes.nonSticky} align="left">
															<Box ml={4}>
																{rightSideData.data.expected}
															</Box>
														</TableCell>
													</TableRow>
												</TableBody>
												<TableHead>
													<TableRow>
														<TableCell className={classes.sticky} style={{ backgroundColor: '#fafafa' }}> {'Actual:  '} </TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													<TableRow >
														<TableCell className={classes.nonSticky} align="left">
															<Box ml={4}>
																{rightSideData.data.actual}
															</Box>
														</TableCell>
													</TableRow>
												</TableBody>
												<TableHead>
													<TableRow>
														<TableCell className={classes.sticky} style={{ backgroundColor: '#fafafa' }}> {'Screenshot:  '} </TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													<TableRow >
														<TableCell className={classes.nonSticky} align="left">
															<Box ml={4}>
																{rightSideData.data.screenshot}
															</Box>
														</TableCell>
													</TableRow>
												</TableBody>
											</>
										}

									</Table>
								</TableContainer>
							</Grid>

						</Grid>


					</CardContent>
					<Divider />
					<CardActions >
					</CardActions>
				</>
				}
			</Card>
		</div >
	)

}

export default ReportsPageUI;
