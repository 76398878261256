import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import { Topbar } from './components';

const useStyles = makeStyles(() => ({
  root: {
    //paddingTop: 64,
    // height: '100%'
  },
  content: {
    height: '100%'
  }
}));

const None = props => {
  const { children } = props;

  const classes = useStyles();

  return (
    <main style={{ height: "100%" }} >{children}</main>
  );
};

None.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default None;
