import React from 'react';
import AceEditor from 'react-ace';

// import "ace-builds/webpack-resolver" // https://github.com/securingsincity/react-ace/issues/772

// modes
import 'ace-builds/src-min-noconflict/mode-groovy';


// snippets
import 'ace-builds/src-min-noconflict/snippets/groovy';

// theme
import 'ace-builds/src-min-noconflict/theme-github';
import 'ace-builds/src-min-noconflict/theme-dracula';
import 'ace-builds/src-min-noconflict/theme-xcode';
import "ace-builds/src-min-noconflict/ext-language_tools"

//import 'brace/ext/language_tools';

const OneEditor = props => {
    //const lan = props.lan || 'java';
    const mode = props.mode || 'java';
    let theme = 'github';
    if (props.theme) {
        theme = (props.theme === 'dark') ? 'dracula' : props.theme
    }
    const value = props.value;
    let elevation = 1;
    if (props.elevation !== undefined) {
        elevation = props.elevation
    }

    return (
        <AceEditor
            height={props.height}
            //maxLines={Infinity}
            mode={mode}
            theme={theme}
            name="oc_ace"
            width="100%"
            //onLoad={onLoad}
            onChange={props.onChange}
            fontSize={14}
            showPrintMargin={false}
            showGutter={true}
            highlightActiveLine={true}
            value={value}
            setOptions={{
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
                enableSnippets: true,
                showLineNumbers: true,
                tabSize: 2,
            }}
            editorProps={{
                $blockScrolling: Infinity
            }}
            commands={[{   // commands is array of key bindings.
                name: 'runCode', //name for the key binding.
                bindKey: { win: 'Ctrl-enter', mac: 'Command-return' }, //key combination used for the command.
                exec: props.onRun  // name of the command to rebind
            }]}
        />
    )
};

export default OneEditor;


