import React, { useRef, useState } from "react";
import JSZip from "jszip";
import throttle from "lodash.throttle";
import { saveAs } from "file-saver";
import { Paper, Typography, Button, TextField } from "@material-ui/core";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Icon from '@material-ui/core/Icon';
import { userProjectUpdate, userLoadingChange, userProjectsUpdate } from 'actions/userActions';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

export default function FolderUploader(props) {
    const dispatch = useDispatch();
    const inputRef = useRef(null);
    const [progress, setProgress] = useState(-1);
    const [files, setFiles] = useState([]);
    const [localSystemPath, setLocalSystemPath] = useState(props.localSystemPath || '');


    const onZipUpdate = (metadata) => {
        setProgress(metadata.percent);
        console.log("progression: " + metadata.percent.toFixed(2) + " %");
        if (metadata.currentFile) {
            console.log("current file = " + metadata.currentFile);
        }
    };
    const throttledZipUpdate = throttle(onZipUpdate, 50);

    const onFilesChange = (e) => {
        const files = Array.from(e.target.files);
        setFiles(files);
    }

    const updateLocalSystemPath = async () => {
        // send localSystemPath to server
        let res = await fetch(`/api/repositories/update-local-system-path`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${props.userToken}`
            },
            body: JSON.stringify({
                _id: props._id,
                localSystemPath: localSystemPath
            }),
        });
        let json = await res.json();
        alert(json.message);
    }

    const onZip = () => {
        if (!localSystemPath || localSystemPath.length <= 0) {
            alert("Please enter the local system path");
            return;
        }
        dispatch(userLoadingChange(true));
        const zip = new JSZip();
        const files = Array.from(inputRef.current.files);

        files.forEach((file) => {
            zip.file(file.webkitRelativePath, file);
        });
        zip
            .generateAsync({ type: "blob" }, throttledZipUpdate)
            .then(async function (content) {
                // saveAs(content, "files.zip");
                const formData = new FormData();
                formData.append(`folderzip.zip`, content);
                formData.append(`language`, props.language || 'java+maven');
                formData.append(`localSystemPath`, localSystemPath);
                console.log("ready to send to server", content);
                let res = await fetch(`/api/repositories/upload?language=${props.language || 'java+maven'}`, {
                    method: "POST",
                    headers: {
                        // "Content-Type": "multipart/form-data",
                        "Authorization": `Bearer ${props.userToken}`
                    },
                    body: formData,
                });
                let statusCode = res.status;
                if (statusCode === 200) {
                    let json = await res.json();
                    dispatch(userLoadingChange(false));
                    console.log(json);
                    let count = json?.data?.count || 0;
                    if (count > 0) {
                        alert(`Upload successful. ${count} methods parsed`);
                    } else {
                        alert("No methods found, please make sure you have uploaded the correct folder");
                    }
                } else {
                    let text = await res.text();
                    dispatch(userLoadingChange(false));
                    alert("Error uploading files, ", text);
                }

            })
            .catch((e) => {
                console.log(e)
                alert("Error uploading files" + e);
                dispatch(userLoadingChange(false));
            });
    };
    return (

        <>

            <Paper variant="outlined" style={{ padding: 10 }}>
                <div>
                    <Typography variant="subtitle1" gutterBottom>
                        Select a code folder to send to the server
                    </Typography>

                    <br />

                    <TextField fullWidth label="Project Path in local system" margin="dense" name="localSystemPath" onChange={e => { setLocalSystemPath(e.target.value) }} required value={localSystemPath} variant="outlined" />
                    {props._id && <>
                        <Button variant="outlined" onClick={() => { updateLocalSystemPath() }}>Update</Button>
                    </>
                    }
                    <br /><br />

                    <label htmlFor="upload-btn">
                        <Button variant="contained" color="primary" component="span" startIcon={<CloudUploadIcon />}>
                            Select Local Directory
                        </Button>
                    </label>

                    <input style={{ display: "none" }} id="upload-btn" ref={inputRef} type="file" webkitdirectory="true" onChange={onFilesChange} />

                    <br /><br /><br />

                    <div>
                        {files.length > 0 &&
                            <Button
                                variant="contained"
                                color="primary"
                                //className={classes.button}
                                endIcon={<Icon>send</Icon>}
                                onClick={onZip}
                            > Upload Local Directory
                            </Button>
                        }
                        {/* <div>
                            <button onClick={onZip} disabled={files?.length <= 0} >Upload files</button>
                        </div> */}
                        {/* {progress && progress > 0 &&
                            <progress max="100" value={progress}>
                                {progress?.toFixed(2)}%{" "}
                            </progress>
                        } */}

                        {/* <h3>Selected Files</h3>
                    {files.map((file) => (
                        <div key={file.webkitRelativePath}>{file.webkitRelativePath}</div>
                    ))} */}
                    </div>

                </div>

            </Paper>

            {props.updated && <>
                <br /><br />
                <Paper variant="outlined" style={{ padding: 10 }}>
                    <Typography variant="subtitle1" gutterBottom>
                        {"Last updated: " + props.updated}
                    </Typography>
                </Paper>
            </>}

        </>
    );
}
