import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
  TextField
} from '@material-ui/core';
import { useSelector } from 'react-redux';
//import FunctionsProvider from '../../../../components/commons/FunctionsProvider';

const useStyles = makeStyles(() => ({
  root: {}
}));

const Password = props => {
  const { className, ...rest } = props;
  const token = useSelector(state => state.user.token);

  const classes = useStyles();

  const [values, setValues] = useState({
    password: '',
    confirm: ''
  });

  async function updatePassword() {
    const response = await fetch('/api/users/updatePassword', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(values)
    });
    const body = await response.json();
    console.log(body);
    if (body?.status === 'success') {
      alert('Password updated successfully');
    } else {
      alert('Password update failed');
    }
  }

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form>
        <CardHeader
          subheader="Update password"
          title="Password"
        />
        <Divider />
        <CardContent>
          <TextField fullWidth label="Password" name="password" type="password" variant="outlined"
            onChange={handleChange}
            value={values.password}
          />
          {/* < FunctionsProvider /> */}
          <TextField
            fullWidth
            label="Confirm password"
            name="confirm"
            onChange={handleChange}
            style={{ marginTop: '1rem' }}
            type="password"
            value={values.confirm}
            variant="outlined"
          />
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            onClick={updatePassword}
            color="primary"
            variant="outlined"
          >
            Update
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

Password.propTypes = {
  className: PropTypes.string
};

export default Password;
