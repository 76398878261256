import { IconButton, makeStyles, Snackbar } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect, useRef } from 'react';


const useStyles = makeStyles((theme) => ({
    statsLink: {
        cursor: 'pointer',
    },
}))

async function generateId() {
    let response = await fetch(`/api/getId`)
    let json = await response.json();
    return json.id
}

async function post(url, userToken, data) {
    let res = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${userToken}` },
        body: JSON.stringify(data)
    });
    let json = await res.json();
    return json;
}

async function put(url, userToken, data) {
    let res = await fetch(url, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${userToken}` },
        body: JSON.stringify(data)
    });
    let json = await res.json();
    return json;
}

async function get(url, userToken) {
    let res = await fetch(url, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${userToken}` }
    });
    let json = await res.json();
    return json;
}




async function del(url, userToken, data) {
    let res = await fetch(url, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${userToken}` },
        body: JSON.stringify(data)
    });
    let json = await res.json();
    return json;
}


function CustomAlert(props) {
    let { toggleMessage, snakbarOpen, setSnakbarOpen } = props.data
    return (
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right', }}
            open={snakbarOpen}
            autoHideDuration={6000}
            onClose={() => setSnakbarOpen(false)}
            message={<span id="message-id">{toggleMessage}</span>}
            action={[
                <IconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    onClick={() => setSnakbarOpen(false)}
                >
                    <CloseIcon />
                </IconButton>,
            ]}
        />
    )
}



function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}


function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export {
    generateId,
    CustomAlert,
    post,
    get,
    del,
    put,

    useInterval,
    numberWithCommas,

};
