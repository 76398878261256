import {
  Avatar,


  Button, Card,
  CardActions,
  CardContent,


  Divider, Typography
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { userUpdateToken } from 'actions/userActions';

const useStyles = makeStyles(theme => ({
  root: {},
  details: {
    display: 'flex'
  },
  avatar: {
    marginLeft: 'auto',
    height: 100,
    width: 100,
    flexShrink: 0,
    flexGrow: 0
  },
  progress: {
    marginTop: theme.spacing(2)
  },
  uploadButton: {
    marginRight: theme.spacing(2)
  }
}));

const AccountProfile = props => {
  const { className, ...rest } = props;
  const token = useSelector(state => state.user.token);
  const accessToken = useSelector(state => state.user.accessToken);
  const name = useSelector(state => state.user.name);
  const dispatch = useDispatch();
  const classes = useStyles();

  const [t, setT] = React.useState(accessToken);

  async function refreshToken() {
    setT('Refreshing ...');
    let res = await fetch(`/api/users/refreshToken`, { headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${token}` }, });
    let json = await res.json();
    console.log('refreshToken json:', json);
    setT(json.accessToken);
    dispatch(userUpdateToken(json.accessToken));
    // user.accessToken = json.accessToken;
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent>
        <div className={classes.details}>
          <div>
            <Typography
              gutterBottom
              variant="h2"
            >
              {name}
            </Typography>
            <br /><br />
            <Typography
              className={classes.locationText}
              color="textSecondary"
              variant="body1"
            >
              {`Token: `}
            </Typography>
            <Typography
              className={classes.dateText}
              color="textSecondary"
              variant="body1"
            >
              {`${t}`}
            </Typography>
          </div>
          <Avatar
            className={classes.avatar}
            src={'https://static.onecompiler.com/images/blank-profile.png'}
          />
        </div>
        {/* <div className={classes.progress}>
          <Typography variant="body1">Profile Completeness: 70%</Typography>
          <LinearProgress
            value={70}
            variant="determinate"
          />
        </div> */}
      </CardContent>
      <Divider />
      <CardActions>
        <Button
          className={classes.uploadButton}
          color="primary"
          variant="text"
          onClick={refreshToken}
        >
          Refresh Token
        </Button>
        {/* <Button variant="text">Remove picture</Button> */}
      </CardActions>
    </Card>
  );
};

AccountProfile.propTypes = {
  className: PropTypes.string
};

export default AccountProfile;
