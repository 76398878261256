import { withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import React from 'react';

const AntTabs = withStyles({
    root: {
        borderBottom: '1px solid #e8e8e8',
    },
    indicator: {
        backgroundColor: '#3f51b5',
    },
})(Tabs);

const AntTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        minWidth: 72,
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing(4),

        '&:hover': {
            color: '#7885cb',
            opacity: 1,
        },
        '&$selected': {
            color: '#3f51b5',
            //fontWeight: theme.typography.fontWeightMedium,
        },
        '&:focus': {
            color: '#3f51b5',
        },
    },
    selected: {},
}))((props) => <Tab disableRipple {...props} />);


export {
    AntTabs,
    AntTab
};
