const React = require('react')
const PropTypes = require('prop-types')

class CodeBlock extends React.PureComponent {
  constructor(props) {
    super(props)
    this.setRef = this.setRef.bind(this)
  }

  setRef(el) {
    this.codeEl = el
  }

  componentDidMount() {
    this.highlightCode()
  }

  componentDidUpdate() {
    this.highlightCode()
  }

  highlightCode() {
    if (window.hljs) {
      window.hljs.highlightBlock(this.codeEl)
    } else {
      //  highlight.min.js is not ready! schedule it??
    }
  }

  render() {
    return (
      <pre>
        <code ref={this.setRef} className={this.props.language ? `language-${this.props.language}` : 'plaintext'}>
          {this.props.value}
        </code>
      </pre>
    )
  }
}

CodeBlock.defaultProps = {
  language: ''
}

CodeBlock.propTypes = {
  value: PropTypes.string.isRequired,
  language: PropTypes.string
}

export default CodeBlock
