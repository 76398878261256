import { USER_LANGUAGE_CHANGE, USER_LOGIN, USER_LOGOUT, USER_THEME_CHANGE, USER_ACCESS_TOKEN_CHANGE, USER_LOADING_CHANGE, USER_PROJECT_UPDATE, USER_PROJECTS_UPDATE } from './types';
//import store from '../store';

export const userLanguageChange = (language) => dispatch => {
    //console.log(`userLanguageChange: language: ${language}`);
    dispatch({
        type: USER_LANGUAGE_CHANGE,
        payload: language
    });
}

export const userThemeChange = (theme) => (dispatch, getState) => {
    //console.log(`userThemeChange: theme: ${theme}`);
    dispatch({
        type: USER_THEME_CHANGE,
        payload: theme
    });
    let currentState = getState();
    localStorage.setItem('reduxState', JSON.stringify({ ...currentState }));
}

export const userLoadingChange = (loading) => (dispatch, getState) => {
    //console.log(`userThemeChange: theme: ${theme}`);
    dispatch({
        type: USER_LOADING_CHANGE,
        payload: loading
    });
    let currentState = getState();
}

export const userProjectUpdate = (projectData) => (dispatch, getState) => {
    dispatch({
        type: USER_PROJECT_UPDATE,
        payload: projectData
    });
    let currentState = getState();
    localStorage.setItem('reduxState', JSON.stringify({ ...currentState }));
}

export const userProjectsUpdate = (projectsData) => (dispatch, getState) => {
    dispatch({
        type: USER_PROJECTS_UPDATE,
        payload: projectsData
    });
    let currentState = getState();
    localStorage.setItem('reduxState', JSON.stringify({ ...currentState }));
}

export const userLogin = loginData => (dispatch, getState) => {
    //console.log(`userLogin`, loginData);
    dispatch({
        type: USER_LOGIN,
        payload: loginData
    });
    let currentState = getState();
    localStorage.setItem('reduxState', JSON.stringify({ ...currentState }));
}

/*
export const updateUserDetails = userDetails => dispatch => {
    console.log(`userLogin`, userDetails);
    fetch(`/api/users/`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${store.getState().user.token}` },
        body: JSON.stringify(userDetails)
    }).then(res => res.json())
      .then(json => {
        console.log(json)
        dispatch(userLogin(json));
     });
}
*/


export const refreshUserDetails = (token) => dispatch => {
    if (token) {
        //console.log('refreshing with token:', token);
        fetch('/api/users/me', {
            headers: { "Authorization": `Bearer ${token}` },
        })
            .then(res => res.json())
            .then(json => {
                //console.log(json)
                dispatch(userLogin(json));
            });
    } else {
        console.log('unable to refresh');
    }
}



export const userUpdateToken = (t) => (dispatch, getState) => {
    dispatch({
        type: USER_ACCESS_TOKEN_CHANGE,
        payload: t
    });
    let currentState = getState();
    localStorage.setItem('reduxState', JSON.stringify({ ...currentState }));
}

export const userLogout = () => dispatch => {
    dispatch({
        type: USER_LOGOUT,
        payload: {}
    })
    localStorage.removeItem('reduxState');
}