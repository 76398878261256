import Collapse from '@material-ui/core/Collapse';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';

import IconButton from "@material-ui/core/IconButton";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import SubtitlesIcon from '@material-ui/icons/Forum';
import MenuIcon from "@material-ui/icons/Menu";
import { withRouter } from "react-router";


import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import OneMarkdown from './components/commons/OneMarkdown.js';

// import { Link } from '../server/routes.js';
import { Link } from 'react-router-dom';
// import { useHistory, useParams } from "react-router-dom";

const drawerWidth = 240;

const styles = theme => ({
    root: {
        display: 'flex'
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up("md")]: {
            display: "none"
        }
    },
    drawer: {
        [theme.breakpoints.up("md")]: {
            width: drawerWidth,
            flexShrink: 0
        }
    },
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        width: '100%',

        padding: theme.spacing(3),
        //width: `calc(100% - ${drawerWidth}px)`,
    },
    toolbar: theme.mixins.toolbar,
    menuItem: {
        textDecoration: "none",
        color: theme.palette.text.primary
    },
    title: {
        //marginBottom: theme.spacing(5)
    },
    // subItem: {
    //     paddingLeft: 56
    // },
    nested: {
        paddingLeft: theme.spacing(4),
    },
});


class DocsPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            codeRenderer: null,
            mobileOpen: false,
            // openKeys: (props.query.path && props.query.path.split('/').length === 2) ? [props.query.path.split('/')[0]] : []
        }
    }


    async componentDidMount() {
        let { id, path1, path2 } = this.props.match.params;
        if (!id) {
            id = 'java';
        }
        let path = '';
        let openKeys = [];
        if (path1) {
            path += '/' + path1;
            openKeys = [path1]
        }
        if (path2) {
            path += '/' + path2;
        }

        //console.log('getInitialProps path:', path);
        let result = {};
        let r1 = await fetch(`/api/docs/${id}`);
        r1 = await r1.json();
        result.index = r1.index;
        if (path) {
            let r2 = await fetch(`/api/docs/${id}/${path}`);
            r2 = await r2.json();
            result.content = r2.content;
            result.title = r2.title;

        } else {
            result.content = r1.content;
            result.title = r1.title;
        }

        //NProgress.done();
        //return result;

        this.setState({ content: result.content, title: result.title, index: result.index, id, openKeys });

        //this.setState({ codeRenderer: CodeBlock });
    }

    setMobileOpen = () => {
        this.setState({
            mobileOpen: !this.state.mobileOpen
        })
    }

    handleDrawerToggle = () => {
        // this.setMobileOpen(!mobileOpen);
        this.setState({
            mobileOpen: !this.state.mobileOpen
        })
    };


    toggleListItemClick = (id) => e => {
        if (this.state.openKeys.includes(id)) { // remove
            this.setState({
                openKeys: [...this.state.openKeys.filter(ele => ele !== id)]
            })
        } else { // add
            this.setState({
                openKeys: [...this.state.openKeys, id]
            })
        }
    }


    render() {
        // if (this.props.errorCode) { return <Error statusCode={this.props.errorCode} /> }
        const { classes, container } = this.props;

        let renderers = {};
        if (this.state.codeRenderer) {
            renderers.code = this.state.codeRenderer;
        }

        let indexContent = null;
        if (this.state.index) {
            indexContent = this.state.index.map(root =>
                <React.Fragment key={root.path}>
                    <ListItem button onClick={this.toggleListItemClick(root.path)}>
                        <ListItemText primary={root.title} />
                        {(this.state.openKeys.includes(root.path)) ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={this.state.openKeys.includes(root.path)} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {root.links.map(link =>
                                <Link key={link.path} to={`/docs/${root.path}/${link.path}`}  >
                                    <a className={classes.menuItem}>
                                        <ListItem button className={classes.nested} key={link.path} selected={(this.props.query && this.props.query.path === `${root.path}/${link.path}`) ? true : false}>
                                            <ListItemText primary={link.title} primaryTypographyProps={{ /*color: 'textSecondary'*/ }} />
                                        </ListItem>
                                    </a>
                                </Link>
                            )}
                        </List>
                    </Collapse>
                    <Divider />
                </React.Fragment>
            );
        }


        let drawerContent = <div>
            <div className={classes.toolbar} />
            {indexContent}
            <Divider />
            <List>
                {/* <ListItem button component="a" href={`https://github.com/onecompiler/docs/tree/dev/${this.props.query.id}`}> */}
                <ListItem button component="a" href="https://github.com/medha/docs/" >
                    <SubtitlesIcon style={{ marginRight: 5 }} />
                    <ListItemText primary={' Improve Content'} />
                </ListItem>
            </List>
        </div>


        return (
            <div className={classes.root}>

                {/* <NavBar position={'fixed'} /> */}

                <nav className={classes.drawer} aria-label="mailbox folders">
                    {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                    <Hidden mdUp implementation="css" >
                        <Drawer
                            container={container}
                            variant="temporary"
                            anchor={'left'}
                            open={this.state.mobileOpen}
                            onClose={this.handleDrawerToggle}
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            ModalProps={{
                                keepMounted: true, // Better open performance on mobile.
                            }}
                        >
                            {drawerContent}
                        </Drawer>
                    </Hidden>
                    <Hidden smDown implementation="css">
                        <Drawer
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            variant="permanent"
                            open
                        >
                            {drawerContent}
                        </Drawer>
                    </Hidden>
                </nav>

                <main className={classes.content}>
                    <Container maxWidth={'lg'}>
                        <div className={classes.toolbar} />
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={this.handleDrawerToggle}
                            className={classes.menuButton}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="h2" component="h1" gutterBottom align="center" className={classes.title}>
                            {this.state.title}
                        </Typography>
                        <OneMarkdown markdown={this.state.content || ''} />
                    </Container>
                </main>
            </div>
        );
    }
}

DocsPage.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    // loggedInUserObj: state.user,
    // token: state.user.token,
})

export default withRouter(connect(mapStateToProps, {})(withStyles(styles)(DocsPage)));