import { USER_LANGUAGE_CHANGE, USER_LOGIN, USER_LOGOUT, USER_THEME_CHANGE, USER_ACCESS_TOKEN_CHANGE, USER_LOADING_CHANGE, USER_PROJECT_UPDATE, USER_PROJECTS_UPDATE } from '../actions/types';
const isServer = typeof window === 'undefined'

const initialState = {
    version: 'v4',
    userLoggedIn: false,
    _id: null,
    userId: null,
    name: null,
    email: null,
    token: null,
    accessToken: null,
    picture: null,
    thumbnail: null,
    loginTime: new Date(),
    orgs: [],
    language: 'en',
    theme: 'light',
    loading: false,
    isServer
}

export default function (state = initialState, action) {
    switch (action.type) {
        case USER_LOGIN:
            return {
                ...state,
                userLoggedIn: true,
                ...action.payload,
                loginTime: new Date()
            }
        case USER_PROJECT_UPDATE:
            return {
                ...state,
                token: action.payload.token,
                project: action.payload.project,
            }
        case USER_PROJECTS_UPDATE:
            return {
                ...state,
                projects: action.payload,
            }
        case USER_LOGOUT:
            return {
                ...state,
                userLoggedIn: false,
                _id: null,
                userId: null,
                name: null,
                email: null,
                token: null,
                picture: null,
                thumbnail: null,
            }
        case USER_LANGUAGE_CHANGE:
            return {
                ...state,
                userLanguage: action.payload
            }
        case USER_THEME_CHANGE:
            return {
                ...state,
                theme: action.payload
            }
        case USER_ACCESS_TOKEN_CHANGE:
            return {
                ...state,
                accessToken: action.payload
            }
        case USER_LOADING_CHANGE:
            return {
                ...state,
                loading: action.payload
            }
        default:
            return state;
    }
}