import React, { useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Divider, Drawer } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
// import PeopleIcon from '@material-ui/icons/People';
// import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
// import TextFieldsIcon from '@material-ui/icons/TextFields';
// import ImageIcon from '@material-ui/icons/Image';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import SettingsIcon from '@material-ui/icons/Settings';
// import LockOpenIcon from '@material-ui/icons/LockOpen';
import GitHubIcon from '@material-ui/icons/GitHub';
// import PlayArrowIcon from '@material-ui/icons/PlayArrow';
// import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded';
import PlayCircleFilledRoundedIcon from '@material-ui/icons/PlayCircleFilledRounded';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import BuildIcon from '@material-ui/icons/Build';
import { Profile, SidebarNav, /*UpgradePlan*/ } from './components';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
// import DropDownPlus from 'views/Account/components/DropDownPlus';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { get, post } from 'components/commons/common-components';
import { userProjectUpdate, userLoadingChange, userProjectsUpdate } from 'actions/userActions';

const options = [
	{ value: 'chocolate sdg kjsa', label: 'Chocolate kjhsdg kjashgd fvkjagdf jadgf dag ' },
	{ value: 'strawberry', label: 'Strawberry' },
	{ value: 'vanilla', label: 'Vanilla' }
]

const useStyles = makeStyles(theme => ({
	drawer: {
		width: 240,
		[theme.breakpoints.up('lg')]: {
			marginTop: 64,
			height: 'calc(100% - 64px)'
		}
	},
	root: {
		backgroundColor: theme.palette.white,
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		padding: theme.spacing(2)
	},
	divider: {
		margin: theme.spacing(2, 0)
	},
	nav: {
		marginBottom: theme.spacing(2)
	}
}));

const Sidebar = props => {
	const { open, variant, onClose, className, ...rest } = props;
	const token = useSelector(state => state.user.token);

	const dispatch = useDispatch();

	const projects = useSelector(state => state.user.projects);

	const project = useSelector(state => state.user.project);

	const classes = useStyles();

	const pages = [
		// {
		// 	title: 'Manage UI Tests (OLD)',
		// 	href: '/tests-ui',
		// 	icon: <BuildIcon />
		// },
		{
			title: 'Manage Tests',
			href: '/tests',
			icon: <DashboardIcon />
		},
		// {
		// 	title: 'Manage Tests (OLD)',
		// 	href: '/testsOld',
		// 	icon: <DashboardIcon />
		// },
		{
			title: 'Manage Tasks',
			href: '/tasks',
			icon: <BuildIcon />
		},
		{
			title: 'Run Tests',
			href: '/run',
			icon: <PlayCircleFilledRoundedIcon />
		},
		{
			title: 'View Reports',
			href: '/reports',
			icon: <EqualizerIcon />
		},
		{
			title: 'Repositories',
			href: '/repositories',
			icon: <GitHubIcon />
		},
		// {
		//   title: 'Organizations',
		//   href: '/users',
		//   icon: <PeopleIcon />
		// },
		// {
		//   title: 'Products',
		//   href: '/products',
		//   icon: <ShoppingBasketIcon />
		// },
		// {
		//   title: 'Authentication',
		//   href: '/sign-in',
		//   icon: <LockOpenIcon />
		// },
		// {
		//   title: 'Typography',
		//   href: '/typography',
		//   icon: <TextFieldsIcon />
		// },
		// {
		//   title: 'Icons',
		//   href: '/icons',
		//   icon: <ImageIcon />
		// },
		{
			title: 'Projects',
			href: '/projects',
			icon: <AccountTreeIcon />
		},
		{
			title: 'Account',
			href: '/account',
			icon: <AccountBoxIcon />
		},
		{
			title: 'Settings',
			href: '/settings',
			icon: <SettingsIcon />
		}
	];

	useEffect(() => {
		init();
	}, []);

	async function init() {
		console.log('load the projects dropdown values');
		let rows = await get(`/api/projects`, token);
		if (rows && Array.isArray(rows) && rows.length > 0) {
			let projectsData = (rows || []).map(ele => ({ _id: ele._id, name: ele.name }));
			dispatch(userProjectsUpdate(projectsData));
		}

	}


	let handleProjectChange = async (selectedOption) => {
		dispatch(userLoadingChange(true));
		console.log(selectedOption);
		let json = await post(`/api/users/updateProject`, token, { projectId: selectedOption.value });
		console.log(json);
		dispatch(userLoadingChange(false));
		if (json.status === 'failed') {
			alert(json.message);
		} else {
			dispatch(userProjectUpdate(json.data));
		}
		setTimeout(() => {
			window.location.reload();
		}, 500);
	};

	return (
		<Drawer
			anchor="left"
			classes={{ paper: classes.drawer }}
			onClose={onClose}
			open={open}
			variant={variant}
		>
			<div
				{...rest}
				className={clsx(classes.root, className)}
			>
				<Profile />
				<div style={{ paddingTop: 15, paddingBottom: 5 }}>
					<Select
						value={project ? { value: project._id, label: project.name } : null}
						options={(projects || []).map(ele => ({ value: ele._id, label: ele.name }))}
						onChange={handleProjectChange}
					/>
				</div>
				<Divider className={classes.divider} />
				<SidebarNav
					className={classes.nav}
					pages={pages}
				/>
				{/* <UpgradePlan /> */}
			</div>
		</Drawer>
	);
};

Sidebar.propTypes = {
	className: PropTypes.string,
	onClose: PropTypes.func,
	open: PropTypes.bool.isRequired,
	variant: PropTypes.string.isRequired
};

export default Sidebar;
