import { TextField } from '@material-ui/core';
import Grid from "@material-ui/core/Grid";
import React, { useState } from 'react';

const KeyValBuilder = props => {
    //const classes = useStyles();
    const [rows, setRows] = useState([...(props.rows || []), { name: '', value: '' }]);

    function handleRows(field, index, val) {
        let fields = ['name', 'value'];
        let newRow = { ...rows[index] };
        let newRows = [...rows];
        if (val) {
            // val added. add a new row if needed
            newRow[field] = val;
            newRows[index] = newRow;
            if ((index + 1) === newRows.length) {
                newRows.push({ name: "", value: "" });
            }
        } else {
            // val deleted. check other val and remove row if needed
            newRow[field] = val;
            if (newRow[fields.filter(ele => ele != field)[0]] || (index + 1) === newRows.length) {  // other field is not empty OR last row
                newRows[index] = newRow;
            } else { // other field is empty && not last row then remove the row
                newRows.splice(index, 1);
            }
        }

        let rowsToUpdate = newRows.filter(ele => (ele.name || ele.value));
        props.handleUpdate(rowsToUpdate); // filter empty row
        setRows(newRows);
    }


    return (
        <>
            {rows && rows.map((row, index) =>
                <Grid container spacing={2} key={index}>
                    <Grid item xs={12} md={3} lg={3} >
                        <TextField fullWidth label="Name" margin="dense" name="name" required variant="outlined" type="text"
                            value={row.name || ''} onChange={e => handleRows('name', index, e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} md={9} lg={9} >
                        <TextField fullWidth label="Value" margin="dense" name="value" required variant="outlined" type="text"
                            value={row.value || ''} onChange={e => handleRows('value', index, e.target.value)}
                        />
                    </Grid>
                </Grid>)}
        </>
    )
}


export default KeyValBuilder;