import { AppBar, Badge, Hidden, IconButton, Toolbar } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import InputIcon from '@material-ui/icons/Input';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import { userLogout } from '../../../../actions/userActions';
// import DropDownPlus from 'views/Account/components/DropDownPlus';


const styles = theme => ({
	root: {
		boxShadow: 'none'
	},
	flexGrow: {
		flexGrow: 1
	},
	signOutButton: {
		marginLeft: theme.spacing(1)
	},

});

class Topbar extends Component {

	componentDidMount() {
		if (!this.props.userId) {
			this.props.history.push('/');
		}
	}

	render() {
		const { classes, className, onSidebarOpen, userLogout, history, staticContext, userId, ...rest } = this.props;
		return (
			<AppBar
				{...rest}
				className={clsx(classes.root, className)}
			>
				<Toolbar>
					<RouterLink to="/tests">
						<Typography variant="h4" gutterBottom style={{ color: "white" }}>
							Medha
						</Typography>
						{/* <img
              alt="Logo"
              src="/images/logos/logo--white.svg"
            /> */}
					</RouterLink>
					<div className={classes.flexGrow} />
					<Hidden mdDown>

						{/* <IconButton color="inherit">
							<Badge
								badgeContent={2}
								color="primary"
								variant="dot"
							> <NotificationsIcon />
							</Badge>
						</IconButton> */}
						<IconButton
							onClick={() => {
								userLogout();
								history.push('/sign-in')
							}}
							className={classes.signOutButton}
							color="inherit"
						>
							<InputIcon />
						</IconButton>
					</Hidden>
					<Hidden lgUp>
						<IconButton
							color="inherit"
							onClick={onSidebarOpen}
						>
							<MenuIcon />
						</IconButton>
					</Hidden>
				</Toolbar>
			</AppBar>
		);
	}
}

Topbar.propTypes = {
	className: PropTypes.string,
	onSidebarOpen: PropTypes.func
};

const mapStateToProps = state => ({
	userId: state.user._id
});

export default withRouter(connect(mapStateToProps, { userLogout })(withStyles(styles)(Topbar)));

