import {
	Box, Button, Card, CardActions, CardContent, CardHeader,
	Divider, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography
} from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import {
	AccountTree as AccountTreeIcon, ArrowBackRounded as ArrowBackRoundedIcon,
	Assessment as AssessmentIcon, Check as CheckIcon, Launch as LaunchIcon,
	Clear as ClearIcon, FormatListNumbered as FormatListNumberedIcon, KeyboardArrowDown as KeyboardArrowDownIcon,
	KeyboardArrowUp as KeyboardArrowUpIcon, ShowChart as ShowChartIcon
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import Tile from 'components/reports/Tile';
import fromNow from 'fromnow';
import MaterialTable from 'material-table';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from "react-router-dom";
import { get, post, del } from './components/commons/common-components';
import GroupIcon from '@material-ui/icons/Group';
import CreatableSelect from "react-select/lib/Creatable";
import { useDispatch } from 'react-redux';
import { userProjectUpdate, userLoadingChange, userProjectsUpdate } from 'actions/userActions';

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(2)
	},
	table: {
		minWidth: 250,
	},
}))

const ProjectsVariablesPage = props => {
	const dispatch = useDispatch();
	const classes = useStyles();
	const token = useSelector(state => state.user.token);
	const [data, setData] = useState([]);
	const history = useHistory();

	const [project, setProject] = useState({});
	const [variables, setVariables] = useState([]);

	const { id } = useParams();
	console.log('id', id);


	useEffect(() => {
		init();
	}, [id]);

	async function init() {
		if (id) {
			let row = await get(`/api/projects/${id}`, token);
			setProject(row);
			setVariables(row.variables ? [...row.variables] : []);
		}
	}

	async function onRowAdd(row) {
		let currentVariables = project.variables || [];
		currentVariables.push(row);
		await saveVariables(currentVariables);
	}

	async function onRowUpdate(newRow, oldRow) {
		let currentVariables = project.variables || [];
		let index = currentVariables.findIndex(v => v.variable === oldRow.variable);
		currentVariables[index] = newRow;
		await saveVariables(currentVariables);
	}

	async function onRowDelete(oldRow) {
		let currentVariables = project.variables || [];
		let index = currentVariables.findIndex(v => v.variable === oldRow.variable);
		currentVariables.splice(index, 1);
		await saveVariables(currentVariables);
	}


	async function saveVariables(v) {
		let json = await post(`/api/projects/variables`, token, {
			projectId: project._id,
			variables: v
		});
		if (json.status === 'failed') {
			alert(json.message);
		} else {
			init();
		}
	}


	async function updateEnvironments(newEnvironments) {
		dispatch(userLoadingChange(true));
		let json = await post(`/api/projects/enviroments`, token, {
			projectId: project._id,
			environments: newEnvironments
		});
		if (json.status === 'failed') {
			alert(json.message);
		} else {
			init();
		}
		dispatch(userLoadingChange(false));
	}

	let handleChange = value => {
		updateEnvironments(value.map(ele => ele.value));
		// if (value) {
		// 	setProject({ ...project, environments: value.map(ele => ele.value) });
		// } else {
		// 	setProject({ ...project, environments: [] });
		// }
	};

	return (
		<div className={classes.root}>
			<Card>
				<CardHeader
					avatar={<IconButton size="small" aria-label="delete" onClick={() => {
						history.push(`/projects`)
					}}> <ArrowBackRoundedIcon color="primary" /> </IconButton>}
					title={'Projects'}
				/>
				<Divider />
				{project &&
					<CardContent>
						{/** Environments */}

						<Typography variant="h6" gutterBottom>Environments</Typography>

						<CreatableSelect
							value={(project.environments || []).map(e => ({ value: e, label: e }))}
							isMulti name="environments" className="basic-multi-select" classNamePrefix="select" isClearable
							onChange={handleChange}
						/>

						{/* <div style={{ float: 'right', paddingTop: '10px' }}>
							<Button size='small' variant="contained" color="primary" onClick={updateEnvironments}>Save</Button>
						</div> */}

						<br /> <br /><br /> <br />

						<MaterialTable
							title={project.name ? `Project - ${project.name} | Variables` : 'Loading....'}
							columns={[
								{ title: 'Variable', field: 'variable' },
								...(project.environments || []).map(env => {
									return { title: env, field: env }
								})
							]}
							data={variables || []}
							options={{ pageSize: 10 }}
							editable={{
								onRowAdd: onRowAdd,
								onRowUpdate: onRowUpdate,
								onRowDelete: onRowDelete
							}}
						/>
					</CardContent>
				}

				<Divider />
				<CardActions >
				</CardActions>
			</Card>
		</div >
	)

}

export default ProjectsVariablesPage;
