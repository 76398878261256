import {
	Button, Card, CardActions, CardContent, CardHeader, Divider, Grid, TextField, Tabs, Tab, Paper
} from '@material-ui/core';
import Radio from '@material-ui/core/Radio';


import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';

import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MaterialTable from 'material-table';
import { withStyles } from "@material-ui/core/styles";
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React, { Component } from "react";
//import FormControlLabel from '@material-ui/core/FormControlLabel';
import { connect } from 'react-redux';
import validate from 'validate.js';
import { userLoadingChange } from './actions/userActions';
import FolderUploader from 'components/FolderUploader';


const language = [
	{
		value: 'java+maven',
		label: 'java+maven',
	},
	{
		value: 'java+gradle',
		label: 'java+gradle',
	},
];

const schema = {
	url: {
		presence: { allowEmpty: false, message: 'is required' },
		url: true,
		length: {
			maximum: 300
		}
	},

	userName: {
		presence: { allowEmpty: false, message: 'is required' },
		length: {
			maximum: 50
		}
	},

	password: {
		presence: { allowEmpty: false, message: 'is required' },
		length: {
			maximum: 128
		}
	},
}

const styles = theme => ({
	root: {
		padding: theme.spacing(2)
	},
	tabs: {
		paddingLeft: 25
	}
});

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<Typography
			component="div"
			role="tabpanel"
			hidden={value !== index}
			id={`wrapped-tabpanel-${index}`}
			aria-labelledby={`wrapped-tab-${index}`}
			{...other}
		>
			{value === index && <Box p={3}>{children}</Box>}
		</Typography>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};


class RepositoriesPage extends Component {
	state = {
		editMode: false,
		privateRepo: false,

		activeTabValue: 0,

		isValid: true,
		values: {
			language: 'java+maven',
			location: "remote"
		},
		touched: {},
		errors: {},

		_id: null,

		lastPull: null,
		lastPullMessage: null,

		methods: [],



	};

	// generic form updates
	handleChange = event => {
		event.persist();
		console.log(event.target.name, event.target.value);
		this.setState({
			values: {
				...this.state.values,
				[event.target.name]: event.target.type === 'checkbox' ? event.target.checked : event.target.value,
			},
			touched: {
				...this.state.touched,
				[event.target.name]: true
			}
		}, () => {
			let schemaToUse = { ...schema };
			if (!this.state.privateRepo) {
				delete schemaToUse.userName;
				delete schemaToUse.password;
			}
			const errors = validate(this.state.values, schemaToUse);
			this.setState({
				isValid: errors ? false : true,
				errors: errors || {}
			})
		})
	};

	// generic form helper
	hasError = field => {
		return this.state.touched[field] && this.state.errors[field] ? true : false;
	}

	async loadUserRepos() {
		let res = await fetch(`/api/repositories`, { headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${this.props.userToken}` }, });
		let json = await res.json();
		//this.setState({ tableData: json.data });
		if (json.data && json.data.length === 1) {
			let selectedRow = json.data[0];
			this.setState({
				_id: selectedRow._id,
				privateRepo: selectedRow.privateRepo,
				lastPull: selectedRow.lastPull,
				lastPullMessage: selectedRow.lastPullMessage,
				location: selectedRow.location || 'remote',
				language: selectedRow.language || 'java+maven',
				updated: selectedRow.updated,
				values: {
					url: selectedRow.url,
					branch: selectedRow.branch,
					userName: selectedRow.userName,
					password: selectedRow.password,
					description: selectedRow.description,
					location: selectedRow.location || 'remote',
					language: selectedRow.language || 'java+maven',
					localSystemPath: selectedRow.localSystemPath,
				},
				editMode: true,
			});
		}
	}

	async loadMethods() {
		let res = await fetch(`/api/repositories/methods`, { headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${this.props.userToken}` }, });
		let json = await res.json();
		if (json && json.status === 'success') {
			this.setState({
				methods: json.data
			})
		}
	}

	async componentDidMount() {
		//this.loadPageVsActionVsDataElements();
		this.syncData();
	}

	async syncData() {
		this.loadUserRepos();
		this.loadMethods();
	}

	addNewRepository = () => {
		this.setState({
			editMode: true,
			_id: null,
			values: {},
			touched: {},
			errors: {},
		})
	}

	handleFormSubmit = async event => {
		event.preventDefault();
		console.log('upsert!');
		this.props.userLoadingChange(true);
		let res = await fetch('/api/repositories/', {
			method: 'POST',
			headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${this.props.userToken}` },
			body: JSON.stringify({
				_id: this.state._id,
				privateRepo: this.state.privateRepo,
				...this.state.values,
			})
		});
		this.props.userLoadingChange(false);
		let json = await res.json();
		if (json.status === 'success') {
			alert('Saved!');
			this.setState({
				_id: json.data._id
			});
		} else {
			alert('Something went wrong!');
		}
		this.loadUserRepos();
	}

	onRowDelete = async (oldData) => {
		await fetch(`/api/repositories/${oldData._id}`, {
			method: 'DELETE',
			headers: { "Authorization": `Bearer ${this.props.userToken}` }
		});
		this.loadUserRepos();
	}

	render() {
		const { classes } = this.props;

		return (
			<div className={classes.root}>
				<Grid container spacing={4} >
					<Grid item lg={12} md={12} xl={12} xs={12} >

						<Card>

							<CardHeader
								// avatar={<IconButton aria-label="delete" onClick={() => this.setState({ editMode: false })}>
								// 	<ArrowBackRoundedIcon color="primary" />
								// </IconButton>
								// }
								title={'Repository Configuration'}
							/>
							<Divider />
							<CardContent>
								<Paper className={classes.tabs}>
									<Tabs value={this.state.activeTabValue} indicatorColor="primary" textColor="primary"
										onChange={(event, newValue) => {
											this.setState({ activeTabValue: newValue }, () => {
												this.syncData();
											})
										}} >
										<Tab label="Repository" />
										<Tab label="Methods" />
									</Tabs>
								</Paper>
								<Grid container spacing={0}>
									{this.state.activeTabValue === 1 &&
										<Grid item xs={12} md={12} lg={12} >
											<br /><br />
											<MaterialTable
												title=""
												options={{ actionsColumnIndex: -1, pageSize: 10 }}
												localization={{
													header: {
														actions: ''
													},
												}}
												columns={[
													{ title: 'Name', field: 'name' },
													{ title: 'Page', field: 'page' },
													{ title: 'Data Elements', field: 'dataElements', render: rowData => (rowData.dataElements || []).join(', ') },
													{ title: 'Inactive Data Elements', field: 'removedDataElements', render: rowData => (rowData.removedDataElements || []).join(', ') },
													{ title: 'Active', field: 'active' },
												]}
												data={this.state.methods}
												editable={{}}
											/>
										</Grid>

									}

									{this.state.activeTabValue === 0 &&
										<Grid item xs={12} md={6} lg={5} >
											<div style={{ paddingTop: 25 }}></div>

											<div style={{
												paddingLeft: 15,
												paddingRight: 15,
											}}>
												<TextField
													InputLabelProps={{ shrink: true }}
													id="standard-select-language-native"
													select
													fullWidth

													name="language"
													label="Project Language"
													value={this.state.values.language || ''}
													onChange={this.handleChange}
													SelectProps={{
														native: true,
													}}
												// helperText="Please select your language"
												>
													{language.map((option) => (
														<option key={option.value} value={option.value}>
															{option.label}
														</option>
													))}
												</TextField>
											</div>

											<br /><br />

											<FormControl component="fieldset">
												<RadioGroup row aria-label="location" name="location" defaultValue="top" value={this.state.values.location || 'remote'} onChange={this.handleChange} >
													<FormControlLabel value="remote" control={<Radio color="primary" />} label="Remote Repository" labelPlacement="start" />
													<FormControlLabel value="local" control={<Radio color="primary" />} label="Local Repository" labelPlacement="start" />
												</RadioGroup>
											</FormControl>
											<br /><br />

											{this.state.values.location !== 'local' &&
												<>
													<form autoComplete="off" noValidate
														onSubmit={this.handleFormSubmit} >

														<TextField fullWidth label="Git URL" margin="dense" name="url" required variant="outlined" type="text"
															error={this.hasError('url')} helperText={this.hasError('url') ? this.state.errors.url[0] : null}
															value={this.state.values.url || ''} onChange={this.handleChange}
														/>
														<br />

														<TextField fullWidth label="Branch" margin="dense" name="branch" variant="outlined" type="text"
															error={this.hasError('branch')} helperText={this.hasError('branch') ? this.state.errors.branch[0] : 'Leave blank to use default branch'}
															value={this.state.values.branch || ''} onChange={this.handleChange}
														/>
														<br /><br />

														<FormControlLabel
															control={
																<Switch color="primary" name="privateRepo" inputProps={{ 'aria-label': 'primary checkbox' }}
																	checked={this.state.privateRepo}
																	onChange={e => this.setState({ privateRepo: e.target.checked })}
																/>
															}
															label="Private Repository"
														/>

														<br /><br />

														{this.state.privateRepo &&
															<div>
																<TextField fullWidth label="user name" margin="dense" name="userName" required variant="outlined" type="text" autoComplete='off'
																	error={this.hasError('userName')} helperText={this.hasError('userName') ? this.state.errors.userName[0] : null}
																	value={this.state.values.userName || ''} onChange={this.handleChange}
																/>
																<TextField fullWidth label="password or access_token" margin="dense" name="password" required variant="outlined" type="password" autoComplete='off'
																	error={this.hasError('password')} helperText={this.hasError('password') ? this.state.errors.password[0] : null}
																	value={this.state.values.password || ''} onChange={this.handleChange}
																/>
															</div>
														}

														<TextField fullWidth id="outlined-multiline-static" label="Project Description (optional)" name="description" margin="dense" multiline rows="3" variant="outlined"
															value={this.state.values.description || ''} onChange={this.handleChange}
														/>

														{this.state.lastPull && this.state.lastPullMessage &&
															<>
																<br /><br />
																<Paper elevation={1} style={{ paddingLeft: 6, passingRight: 6, paddingTop: 6, paddingBottom: 1 }}>
																	<Typography variant="body1" gutterBottom>
																		{`Last Pull: ${this.state.lastPull}`}
																	</Typography>
																	<Typography variant="body1" gutterBottom>
																		{`Last Pull Message: ${this.state.lastPullMessage}`}
																	</Typography>
																</Paper>
															</>
														}

														<br /><br />
														<Button color="primary" variant="contained" type="submit" disabled={!this.state.isValid} > Sync </Button>
													</form>
												</>
											}
											{this.state.values.location === 'local' &&
												<>
													<br></br>
													<FolderUploader
														_id={this.state._id}
														userToken={this.props.userToken} updated={this.state.updated} language={this.state.values.language} localSystemPath={this.state.values.localSystemPath} />
												</>
											}
											<br />
										</Grid>
									}
								</Grid>
								<br /><br />
							</CardContent>
							<Divider />
							{/* <CardActions >
								
							</CardActions> */}

						</Card>
					</Grid>
				</Grid>
			</div >
		);
	}
}

// const RepositoriesPage = () => {
// 	const classes = useStyles();
// };

const mapStateToProps = state => ({
	userId: state.user._id,
	userToken: state.user.token
});

export default connect(mapStateToProps, { userLoadingChange })(withStyles(styles)(RepositoriesPage));


//export default RepositoriesPage;
