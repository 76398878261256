import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(() => ({
	root: {
		boxShadow: 'none'
	},
	flexGrow: {
		flexGrow: 1
	},
}));

const Topbar = props => {
	const { className, ...rest } = props;
	const token = useSelector(state => state.user.token);
	const classes = useStyles();

	return (
		<AppBar
			{...rest}
			className={clsx(classes.root, className)}
			color="primary"
			position="fixed"
		>
			<Toolbar>
				<RouterLink to={token ? "/tests" : "/"} >
					<Typography variant="h4" gutterBottom style={{ color: "white" }}>
						Medha
					</Typography>
					{/* <img
            alt="Logo"
            src="/images/logos/logo--white.svg"
          /> */}
				</RouterLink>
				<div className={classes.flexGrow} />
				{props.showLogin &&
					<RouterLink to={token ? "/tests" : "/sign-in"} >
						<Typography variant="h5" gutterBottom style={{ color: "white" }}>
							Login
						</Typography>
					</RouterLink>
				}
			</Toolbar>
		</AppBar>
	);
};

Topbar.propTypes = {
	className: PropTypes.string
};

export default Topbar;
