import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout, Home as HomeLayout, None as NoneLayout } from './layouts';

import {
	Dashboard as DashboardView,
	ProductList as ProductListView,
	UserList as UserListView,
	Typography as TypographyView,
	Icons as IconsView,
	Account as AccountView,
	Settings as SettingsView,
	SignUp as SignUpView,
	SignIn as SignInView,
	ForgotPassword as ForgotPasswordView,
	NotFound as NotFoundView
} from './views';
import ManageTeststsPage from 'ManageTeststsPage';
// import ManageTeststsPage_Old from 'ManageTeststsPage_Old';
import ManageAPITestsDataPage from 'ManageAPITestsDataPage';
import RunTeststsPage from 'RunTeststsPage';
import ReportsPage from 'ReportsPage';
import RepositoriesPage from 'RepositoriesPage';
import ManageTasksPage from 'ManageTasksPage';
import ManageUITestsPage from 'ManageUITestsPage';
import HomePage from 'HomePage';

import { Backdrop } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSelector } from 'react-redux';


import APICallPage from 'APICallPage';
import DocsPage from 'DocsPage';
import ProjectsPage from 'ProjectsPage';
import ProjectsUsersPage from 'ProjectsUsersPage';
import ProjectsVariablesPage from 'ProjectsVariablesPage';
import ReportsPageUI from 'ReportsPageUI';

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
}));

const Routes = () => {
	const classes = useStyles();
	const token = useSelector(state => state.user.token);
	const loading = useSelector(state => state.user.loading);
	return (
		<>
			<Backdrop className={classes.backdrop} open={loading} /*onClick={handleClose} */>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Switch>
				{/* <Redirect exact from="/" to="/sign-in" /> */}
				<RouteWithLayout component={HomePage} exact layout={HomeLayout} path="/" />

				<RouteWithLayout component={DocsPage} exact layout={HomeLayout} path="/docs" />
				<RouteWithLayout component={DocsPage} exact layout={HomeLayout} path="/docs/:path1" />
				<RouteWithLayout component={DocsPage} exact layout={HomeLayout} path="/docs/:path1/:path2" />
				<RouteWithLayout component={DocsPage} exact layout={HomeLayout} path="/docs/:path1/:path2/:path3" />

				<RouteWithLayout component={APICallPage} exact layout={NoneLayout} path="/api-trace/:id" />

				<RouteWithLayout component={DashboardView} exact layout={MainLayout} path="/dashboard" />
				{/* <RouteWithLayout component={ManageTeststsPage_Old} exact layout={MainLayout} path="/testsOld" /> */}
				<RouteWithLayout component={ManageTeststsPage} exact layout={MainLayout} path="/tests" />
				<RouteWithLayout component={ManageTeststsPage} exact layout={MainLayout} path="/tests/:testId" />
				<RouteWithLayout component={ManageTeststsPage} exact layout={MainLayout} path="/tests/:testId/data" />

				<RouteWithLayout component={ManageAPITestsDataPage} exact layout={MainLayout} path="/testdata/:testId" />

				<RouteWithLayout component={ManageUITestsPage} exact layout={MainLayout} path="/tests-ui" />

				<RouteWithLayout component={ManageTasksPage} exact layout={MainLayout} path="/tasks" />
				<RouteWithLayout component={ManageTasksPage} exact layout={MainLayout} path="/tasks/:taskId" />

				<RouteWithLayout component={RunTeststsPage} exact layout={MainLayout} path="/run" />
				<RouteWithLayout component={RepositoriesPage} exact layout={MainLayout} path="/repositories" />

				<RouteWithLayout component={ReportsPage} exact layout={MainLayout} path="/reports" />
				<RouteWithLayout component={ReportsPage} exact layout={MainLayout} path="/reports/:jobId" />

				<RouteWithLayout component={ReportsPageUI} exact layout={MainLayout} path="/reports-ui/:jobId" />

				<RouteWithLayout component={UserListView} exact layout={MainLayout} path="/users" />
				<RouteWithLayout component={ProductListView} exact layout={MainLayout} path="/products" />
				<RouteWithLayout component={TypographyView} exact layout={MainLayout} path="/typography" />
				<RouteWithLayout component={IconsView} exact layout={MainLayout} path="/icons" />
				<RouteWithLayout component={AccountView} exact layout={MainLayout} path="/account" />

				<RouteWithLayout component={ProjectsPage} exact layout={MainLayout} path="/projects" />
				<RouteWithLayout component={ProjectsUsersPage} exact layout={MainLayout} path="/projects/:id/users" />
				<RouteWithLayout component={ProjectsVariablesPage} exact layout={MainLayout} path="/projects/:id/variables" />

				<RouteWithLayout component={SettingsView} exact layout={MainLayout} path="/settings" />
				<RouteWithLayout component={SignUpView} exact layout={MinimalLayout} path="/sign-up" />
				<RouteWithLayout component={SignInView} exact layout={MinimalLayout} path="/sign-in" />
				<RouteWithLayout component={ForgotPasswordView} exact layout={MinimalLayout} path="/forgot-password" />
				<RouteWithLayout component={NotFoundView} exact layout={MinimalLayout} path="/not-found" />
				<Redirect to="/not-found" />
			</Switch>
		</>
	);
};

export default Routes;
