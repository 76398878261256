import {
	Button, Card, CardActions, CardContent, CardHeader, Divider, FormControlLabel, Grid, IconButton, Paper, Radio, RadioGroup, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField
} from '@material-ui/core';
import { ArrowBackRounded as ArrowBackRoundedIcon, ArrowDownward as ArrowDownwardIcon, ArrowUpward as ArrowUpwardIcon, Delete as DeleteIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import MaterialTable from 'material-table';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams, useLocation } from "react-router-dom";
import DropDownPlus from "views/Account/components/DropDownPlus";
import { del, get, generateId } from './components/commons/common-components';
import ManageAPITestsDataPage from './ManageAPITestsDataPage';
import ManageUITestsDataPage from 'ManageUITestsDataPage';

function removeArrayElementByIndex(arr, i) {
	arr.splice(i, 1);
	return arr;
}

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(2)
	},
}));

const DEFAULT_VALUES = {
	_id: null, name: '', description: '', testType: 'api',
	order: 0, tags: [],
	task: null, tasks: [],
}

const ManageTeststsPage = props => {
	const history = useHistory();
	const location = useLocation();
	let { testId } = useParams();
	const classes = useStyles();
	const token = useSelector(state => state.user.token);
	const projectId = useSelector(state => state.user.project?._id);
	const [testsTableData, setTestsTableData] = useState([]);
	const [tasksMetadata, setTasksMetadata] = useState([]);
	const [values, setValues] = useState(null);
	const [screen, setScreen] = useState(1);
	const [projectData, setProjectData] = useState({});

	useEffect(() => {
		console.log('location:', location);
		buildHome();
		getProjectData();
	}, [])

	async function getProjectData() {
		console.log('getProjectData for' + projectId);
		let res = await fetch(`/api/projects/${projectId}`, { headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${token}` }, });
		let json = await res.json();
		console.log('json', json);
		setProjectData(json);
	}

	async function buildHome() {
		let tests = await get(`/api/tests`, token);
		setTestsTableData(tests);
		let tasks = await get(`/api/tasks`, token);
		setTasksMetadata(tasks);
		if (testId) {
			let row = tests.find(row => row._id === testId);
			if (row) {
				setValues({ ...row });
				// if (location.pathname.endsWith('data')) {
				// 	setScreen(2);
				// }
			} else {
				setScreen(1);
				alert('Test not found');
			}
		}

	}

	async function handleDelete(row) {
		if (window.confirm(`Are you sure you wish to delete the test?`)) {
			await del(`/api/tests/${row._id}`, token, {});
			buildHome();
		}
	}

	async function handleCreateNew() {
		let _id = await generateId();
		let newValues = { ...DEFAULT_VALUES, _id };
		newValues.order = (testsTableData && testsTableData.length > 0) ? (testsTableData[testsTableData.length - 1].order + 1) : 1;
		if (!projectData.environments[0]) {
			alert('Please create an environment first');
			return;
		}
		newValues.stage = projectData.environments[0];
		setValues({ ...newValues });
	}

	async function handleTaskReOrder(index, direction) {
		let newElementsArray = [...values.tasks];
		if (direction === 'up') {
			let tempElement = newElementsArray[index - 1];
			newElementsArray[index - 1] = newElementsArray[index];
			newElementsArray[index] = tempElement;
		} else {
			let tempId = newElementsArray[index + 1];
			newElementsArray[index + 1] = newElementsArray[index];
			newElementsArray[index] = tempId;
		}
		setValues({ ...values, tasks: newElementsArray });
	}

	function handleChange(event) {
		if (typeof event.persist !== "undefined") { event.persist() }
		setValues({
			...values,
			[event.target.name]: event.target.type === 'checkbox' ? event.target.checked : event.target.value,
		})
	};

	return (
		<div className={classes.root}>
			{!values &&
				<Card>
					<CardHeader title={'Tests'} onClick={() => handleCreateNew()}
						action={<Button color="primary" size="small" variant="outlined"
							disabled={!projectData}
						> New Test </Button>} />
					<Divider />
					<CardContent>
						<Grid item md={12} xs={12} >
							<MaterialTable
								title=""
								options={{ actionsColumnIndex: -1, pageSize: 10 }}
								localization={{ header: { actions: '' }, }}
								columns={[
									{ title: 'Id', field: 'order' },
									{ title: 'Name', field: 'name' },
									{ title: 'Description', field: 'description' },
									{ title: 'Tags', field: 'tags', render: rowData => (rowData.tags) ? rowData.tags.join(', ') : '' },
								]}
								data={testsTableData}
								actions={[
									rowData => ({
										icon: "delete", tooltip: "Delete Rule",
										onClick: (event, rowData) => handleDelete(rowData),
									}),
								]}
								editable={{}}
								onRowClick={((evt, selectedRow) => {
									setValues({ ...selectedRow });
									history.push(`/tests/${selectedRow._id}`);
								})}
							/>
						</Grid>
					</CardContent>
				</Card>
			}
			{values &&
				<Grid container spacing={4} >
					<Grid item lg={12} md={12} xl={12} xs={12} >
						<Card>
							<form autoComplete="off" noValidate >
								<CardHeader
									avatar={<IconButton size="small" aria-label="delete" onClick={() => {
										if (screen === 1) {
											// setValues(null); setScreen(1); buildHome();
											history.push(`/tests`);
											history.go();
										} else if (screen === 2) {
											setScreen(1)
										}
									}}> <ArrowBackRoundedIcon color="primary" /> </IconButton>}
									title={(values && values._id) ? (screen === 1 ? 'Update Test' : 'Update Test Data') : 'Create Test'}
								/>
								<Divider />
								<CardContent>
									{screen === 1 &&
										<>
											<Grid container spacing={0}>
												<Grid item xs={5} >
													<div style={{ paddingTop: 5 }}></div>
													<TextField name="name" fullWidth helperText="Please specify the test name" label="Test Name" margin="dense" required variant="outlined"
														value={values.name || ''} onChange={handleChange} /><br /><br />
													<TextField name="description" fullWidth helperText="Please specify the test description" id="outlined-multiline-static" label="Test Description" margin="dense" multiline rows="3" variant="outlined" required
														value={values.description || ''} onChange={handleChange} /> <br /><br />
													<RadioGroup row aria-label="position" name="testType" value={values.testType} onChange={e => {
														let choice = window.confirm('Changing Test Type deletes existing Tasks, do you want to continue?');
														if (choice) {
															setValues({
																...values,
																testType: e.target.value,
																tasks: [],
																task: null,
															})
														} else {
															// Nothing
														}
													}} >
														<FormControlLabel value="api" control={<Radio color="primary" />} label="API" labelPlacement="start" />
														<FormControlLabel value="ui" control={<Radio color="primary" />} label="UI" labelPlacement="start" />
													</RadioGroup>
												</Grid>
												<Grid item xs={1} > </Grid>
												<Grid item xs={4} >
													<DropDownPlus create placeholder={'Tags'} label={'Tags'} multi={true} options={[]}
														values={values.tags || []} onChange={v => setValues({ ...values, tags: v })} /> <br /><br />
													<TextField name="order" id="outlined-number" variant="outlined" label="Id" type="number" InputLabelProps={{ shrink: true, }} size="small"
														value={values.order} onChange={e => setValues({ ...values, order: parseInt(e.target.value) })} />
												</Grid>
											</Grid>
											<br /><br />
											<Divider />
											<Grid container className={classes.root} spacing={2} direction="row" justify="flex-start" alignItems="center">
												<Grid item xs={6} >
													<DropDownPlus placeholder={'Select Task'} label={'Task'} multi={false}
														options={tasksMetadata.filter(ele => ele.type === values.testType).map(ele => ele.name)}
														values={values.task ? [tasksMetadata.filter(el => el._id === values.task)[0].name] : []}
														onChange={v => v ? setValues({ ...values, task: tasksMetadata.filter(el => el.name === v)[0]._id }) : setValues({ ...values, task: null })}
													/>
												</Grid>
												<Grid item xs={6} >
													<Button color="primary" variant="contained" size="small"
														disabled={values.task === null}
														onClick={async () => {
															let taskInstanceId = await generateId();
															setValues({ ...values, tasks: [...values.tasks, { taskInstanceId: `ti_${taskInstanceId}`, taskId: values.task }] });
														}}> Add </Button>
												</Grid>
											</Grid>

											{values.tasks && values.tasks.length > 0 &&
												<Grid container className={classes.root} spacing={2}>
													<Grid item md={10} xs={12} >
														<TableContainer component={Paper}>
															<Table className={classes.table} aria-label="simple table" size="small">
																<TableHead>
																	<TableRow>
																		<TableCell>ID</TableCell>
																		<TableCell>Task</TableCell>
																		<TableCell align="right"></TableCell>
																	</TableRow>
																</TableHead>
																<TableBody>
																	{values.tasks.map((taskObj, index) =>
																		<TableRow key={`${taskObj.taskInstanceId}`}>
																			<TableCell >{index + 1}</TableCell>
																			<TableCell style={{ cursor: 'pointer' }}
																				onClick={() => history.push('/tasks/' + taskObj.taskId)}
																			>{tasksMetadata.filter(el => el._id === taskObj.taskId)[0].name}</TableCell>
																			<TableCell align="right">
																				<IconButton size="small" onClick={(e) => handleTaskReOrder(index, 'up')} edge="end" disabled={index === 0} > <ArrowUpwardIcon /> </IconButton>
																				<IconButton size="small" onClick={(e) => handleTaskReOrder(index, 'down')} edge="end" disabled={index === (values.tasks.length - 1)}  > <ArrowDownwardIcon /> </IconButton>
																				<IconButton size="small" onClick={(e) => {
																					let taskObjToRemove = values.tasks[index];
																					if (values.taskInstanceIdVsIterations) {
																						let newTaskInstanceIdVsIterations = { ...values.taskInstanceIdVsIterations };
																						delete newTaskInstanceIdVsIterations[taskObjToRemove.taskInstanceId];
																						setValues({
																							...values,
																							tasks: removeArrayElementByIndex([...values.tasks], index),
																							taskInstanceIdVsIterations: newTaskInstanceIdVsIterations
																						});
																					} else {
																						setValues({
																							...values,
																							tasks: removeArrayElementByIndex([...values.tasks], index)
																						});

																					}
																				}} edge="end" > <DeleteIcon /> </IconButton>
																			</TableCell>
																		</TableRow>
																	)}
																</TableBody>
															</Table>
														</TableContainer>
													</Grid>
												</Grid>
											}

											{!(values.tasks && values.tasks.length > 0) &&
												<> <br /><br /><br /><br /><br /><br /><br /><br /></>
											}
										</>
									}
									{screen === 2 &&
										<>
											{values.testType === 'ui' ?
												<ManageUITestsDataPage test={{ ...values }} projectData={projectData} /> :
												<ManageAPITestsDataPage test={{ ...values }} projectData={projectData} />
											}
										</>
									}
								</CardContent>
								{screen === 1 &&
									<>
										<Divider />
										<CardActions >
											{/* <Button disabled={(values.tasks && values.tasks.length > 0 && values.name) ? false : true} color="primary" variant="contained" onClick={updateTest} >
												{(values._id) ? 'Update' : 'Create'}
											</Button> */}
											<Button color="primary" variant="contained" onClick={() => {
												if (testId) {
													setScreen(2);
													// history.push(`${location.pathname}/data`, { shallow: true });
												} else { // first time creating test!
													setScreen(2);
												}
											}} > Next </Button>
										</CardActions>
									</>
								}

							</form>
						</Card>
					</Grid>
				</Grid>
			}

		</div >
	)
}

export default ManageTeststsPage;