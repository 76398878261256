import {
	Button, Divider,
	FormControl, FormControlLabel, Grid, IconButton, Paper, Radio, RadioGroup, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography
} from '@material-ui/core';
import {
	Delete as DeleteIcon, KeyboardArrowDown as KeyboardArrowDownIcon,
	KeyboardArrowUp as KeyboardArrowUpIcon
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { IterationBody, IterationResponse, IterationValues, JsonBody, JsonResponse, TaskDefinition, VariablesComponent } from './ManageTestsDataPageComponents';
import { get, post } from './components/commons/common-components';
import FunctionsProvider from 'components/commons/FunctionsProvider';
var _ = require('lodash');

const useStyles = makeStyles((theme) => ({
	tabs: {
		paddingLeft: 25
	},
	addButton: {
		marginRight: theme.spacing(1.5)
	},
	mt: {
		margin: theme.spacing(1)
	},
	table: {
		minWidth: 650,
	},
	sticky: {
		position: "sticky",
		width: 300,
		left: 0,
		background: "white",
		boxShadow: "5px 2px 5px #f1f4f5",
		zIndex: 10
	},
	nonSticky: {
		width: 300,
		overflowX: "auto",
	},
}))

const ManageAPITestsDataPage = props => {
	const classes = useStyles();
	const params = useParams();
	const token = useSelector(state => state.user.token);
	const projectId = useSelector(state => state.user.project?._id);
	const [test, setTest] = useState({});
	const [stage, setStage] = useState(props.test.stage);
	const [domain, setDomain] = useState('');
	const [taskInstanceIdVsIterations, setTaskInstanceIdVsIterations] = useState({});
	const [taskInstanceIdVsTask, setTaskInstanceIdVsTask] = useState({});
	const [taskInstanceIdVsOpen, setTaskInstanceIdVsOpen] = useState({});
	const [taskInstanceDataVsOpen, setTaskInstanceDataVsOpen] = useState({});
	const [testVariables, setTestVariables] = useState([]);
	const [projectData, setProjectData] = useState(props.projectData);

	useEffect(() => {
		// getProjectData();
		fetchTest(props.test);
	}, []);

	// async function getProjectData() {
	// 	console.log('getProjectData for' + projectId);
	// 	let res = await fetch(`/api/projects/${projectId}`, { headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${token}` }, });
	// 	let json = await res.json();
	// 	console.log('json', json);
	// 	setProjectData(json);
	// }

	async function fetchTest(test) {
		let tasks = await post(`/api/tests/getTasks`, token, { ids: test.tasks.map(ele => ele.taskId) });
		let newTaskIdVsTask = {}
		let newTaskInstanceIdVsTask = {}
		if (tasks) {
			for (let task of tasks) {
				newTaskIdVsTask[task._id] = task;
			}
			for (let task of test.tasks) {
				newTaskInstanceIdVsTask[task.taskInstanceId] = newTaskIdVsTask[task.taskId]
			}
			setTaskInstanceIdVsTask({ ...newTaskInstanceIdVsTask });
		}
		const variables = test.variables ? test.variables : [];
		setTestVariables(variables);
		// updateVariablesColumns(variables);
		delete test.variables;
		setTest(test);
		// if (test.stage) {
		// 	setStage(test.stage);
		// }
		if (test.domain) {
			setDomain(test.domain);
		}
		if (test.taskInstanceIdVsIterations) {
			let _taskInstanceIdVsIterations = _.cloneDeep(test.taskInstanceIdVsIterations);
			for (let stg of projectData.environments) {
				if (_taskInstanceIdVsIterations[stg]) {
					let noOfIterations = Object.values(_taskInstanceIdVsIterations[stg])[0] ? Object.values(_taskInstanceIdVsIterations[stg])[0].length : 0
					for (let t of test.tasks) {
						if (!_taskInstanceIdVsIterations[stg][t.taskInstanceId]) {
							let task = newTaskIdVsTask[t.taskId];
							console.log(task, 'task');
							let newIterationsData = [];
							for (let i = 0; i < noOfIterations; i++) {
								newIterationsData.push({
									"headers": _.cloneDeep(task.headers),
									"queryParameters": _.cloneDeep(task.queryParameters),
									"formParameters": _.cloneDeep(task.formParameters),
									"body": task.body,
									"response": ""
								})
							}
							_taskInstanceIdVsIterations[stg][t.taskInstanceId] = newIterationsData;
						}
					}
				}
			}
			setTaskInstanceIdVsIterations(_taskInstanceIdVsIterations);
		} else {
			addIteration(newTaskInstanceIdVsTask);
		}
	}

	function handleAddIteration() {
		addIteration(taskInstanceIdVsTask);
	}

	function addIteration(taskInstanceIdVsTask) {
		let newtaskInstanceIdVsIterations = { ...taskInstanceIdVsIterations };
		console.log('taskInstanceIdVsTask', taskInstanceIdVsTask);
		for (const [taskInstanceId, task] of Object.entries(taskInstanceIdVsTask)) {
			// do at stage level
			if (!newtaskInstanceIdVsIterations[stage]) {
				newtaskInstanceIdVsIterations = { ...newtaskInstanceIdVsIterations, [stage]: {} }
			}

			let n = {
				"headers": _.cloneDeep(task.headers),
				"queryParameters": _.cloneDeep(task.queryParameters),
				"formParameters": _.cloneDeep(task.formParameters),
				"body": task.body,
				"response": ""
			};

			if (newtaskInstanceIdVsIterations[stage][taskInstanceId] && newtaskInstanceIdVsIterations[stage][taskInstanceId].length > 0) {
				let x = newtaskInstanceIdVsIterations[stage][taskInstanceId][newtaskInstanceIdVsIterations[stage][taskInstanceId].length - 1]
				n = {
					"headers": _.cloneDeep(x.headers),
					"queryParameters": _.cloneDeep(x.queryParameters),
					"formParameters": _.cloneDeep(x.formParameters),
					"body": x.body,
					"response": x.response,
					"responseCode": x.responseCode
				};
			}


			newtaskInstanceIdVsIterations[stage][taskInstanceId] = [
				...newtaskInstanceIdVsIterations[stage][taskInstanceId] || [],
				n
			]
		}
		console.log('newtaskInstanceIdVsIterations', newtaskInstanceIdVsIterations);
		setTaskInstanceIdVsIterations({ ...newtaskInstanceIdVsIterations })
	}

	function removeIteration(index) {
		let newtaskInstanceIdVsIterationsOfCurrentStage = { ...taskInstanceIdVsIterations[stage] };
		for (let [key, value] of Object.entries(newtaskInstanceIdVsIterationsOfCurrentStage)) {
			value.splice(index, 1);
		}
		setTaskInstanceIdVsIterations({
			...taskInstanceIdVsIterations,
			[stage]: newtaskInstanceIdVsIterationsOfCurrentStage
		})
	}

	function updateIterationValues(taskInstanceId, iterationIndex, type, valueIndex, value) {
		let newIterations = [...taskInstanceIdVsIterations[stage][taskInstanceId]]
		if (newIterations[iterationIndex][type] && newIterations[iterationIndex][type].length > 0) {
			let headers = newIterations[iterationIndex][type];
			headers.forEach((map, mapIndex) => {
				if (mapIndex === valueIndex) {
					map.value = value;
				}
			})
		}
		setTaskInstanceIdVsIterations({
			...taskInstanceIdVsIterations,
			[stage]: { ...taskInstanceIdVsIterations[stage], [taskInstanceId]: newIterations }
		});
	}

	function updateIterationBody(taskInstanceId, iterationIndex, type, value) {
		let newIterations = [...taskInstanceIdVsIterations[stage][taskInstanceId]]
		newIterations[iterationIndex][type] = value;
		setTaskInstanceIdVsIterations({
			...taskInstanceIdVsIterations,
			[stage]: {
				...taskInstanceIdVsIterations[stage],
				[taskInstanceId]: newIterations
			}
		})
	}

	async function handleSave() {
		let json = await post(`/api/tests/`, token, { ...test, stage, domain, taskInstanceIdVsIterations: taskInstanceIdVsIterations, variables: testVariables })
		if (json.status === 'success') {
			alert('Saved!');
		} else {
			alert(json.message || 'Something went wrong!');
		}
	}

	async function handleTest() {
		// save the current iterations
		await post(`/api/tests/`, token, { ...test, stage, domain, taskInstanceIdVsIterations: taskInstanceIdVsIterations, variables: testVariables })
		// trigger test
		let json = await get(`/api/tasks/testTasks/api/${test._id}?stage=${stage}`, token)
		if (json.status === 'success') {
			alert(`Test successful`);
		} else {
			alert('Test Failed!');
		}
	}

	let noOfIterations = (taskInstanceIdVsIterations && taskInstanceIdVsIterations[stage] && Object.values(taskInstanceIdVsIterations[stage])) ?
		Object.values(taskInstanceIdVsIterations[stage])[0].length
		:
		0;

	return (
		<div >
			{/** For testing Actions */}
			{/* <FunctionsProvider key={'testing273634'} testVariables={[]} /> */}
			{/** For testing Actions */}
			<Grid container direction="row"> <VariablesComponent classes={classes} testVariables={testVariables} setTestVariables={setTestVariables} /> </Grid>
			<br />
			<Grid container direction="row" justify="space-between" alignItems="flex-start">
				<Grid item>
					<FormControl component="fieldset">
						<RadioGroup row aria-label="position" name="position" value={stage} onChange={e => setStage(e.target.value)} >
							{projectData?.environments?.map((env, index) => <FormControlLabel key={env} value={env} control={<Radio color="primary" />} label={env} labelPlacement="end" />)}
						</RadioGroup>
					</FormControl>
				</Grid>
				<Grid item>
					<Button className={classes.addButton} color="primary" size="small" variant="outlined" onClick={() => handleAddIteration()}> Add Iteration </Button>
				</Grid>
			</Grid>

			<br /><br />
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<TextField fullWidth label="Domain" margin="dense" name="domain" onChange={e => { setDomain(e.target.value) }} required value={domain} variant="outlined" />
				<FunctionsProvider key={'23j3h4gbhjas'} testVariables={testVariables} />
			</div>

			<br /><br />
			<>
				<Grid container spacing={1}>
					<Grid item lg={12}>
						<TableContainer component={Paper}>
							<Table className={classes.table} style={{ tableLayout: "fixed" }}>
								<TableHead>
									<TableRow>
										<TableCell className={classes.sticky} style={{ backgroundColor: '#fafafa' }}> {'Test Iterations:'} </TableCell>
										{Array.from(Array(noOfIterations)).map((xiterationIndex, index) => (
											<TableCell className={classes.nonSticky} key={`col-Iteration${index + 1}`} align="center">
												{`Iteration-${index + 1}`} <IconButton onClick={() => removeIteration(index)}> <DeleteIcon fontSize="small" /> </IconButton>
											</TableCell>
										))}
									</TableRow>
								</TableHead>
								<TableBody>
									{(Object.entries(taskInstanceIdVsTask) || []).map(([taskInstanceId, task], indx) => (
										<React.Fragment key={`taskInstanceId${taskInstanceId}`}>
											<TableRow>
												<TableCell className={classes.sticky}>
													<div style={{ display: 'flex' }}>
														<IconButton size="small" onClick={() => setTaskInstanceIdVsOpen({ ...taskInstanceIdVsOpen, [taskInstanceId]: !(taskInstanceIdVsOpen[taskInstanceId]) })}>
															{taskInstanceIdVsOpen[taskInstanceId] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
														</IconButton>
														<Typography variant="h6" color="primary" className={'pdl10'} > {indx + 1}. {task.name}</Typography>
													</div>
												</TableCell>
												{taskInstanceIdVsIterations[stage] && taskInstanceIdVsIterations[stage][taskInstanceId] && taskInstanceIdVsIterations[stage][taskInstanceId].map((iteration, index) =>
													<TableCell className={classes.nonSticky} key={`empty-Iteration${index + 1}`} align="center">{/** Empty */}</TableCell>)}
											</TableRow>

											{taskInstanceIdVsOpen[taskInstanceId] &&
												<>
													{/** Headers */}
													<TableRow >
														<TableCell className={classes.sticky}>
															<TaskDefinition classes={classes} type={"Headers"} data={task.headers} task={task}
																isOpen={taskInstanceDataVsOpen[taskInstanceId + "-headers"]}
																handleOpen={v => setTaskInstanceDataVsOpen({ ...taskInstanceDataVsOpen, [taskInstanceId + "-headers"]: v })} />
														</TableCell>

														{taskInstanceIdVsIterations[stage] && taskInstanceIdVsIterations[stage][taskInstanceId] && taskInstanceIdVsIterations[stage][taskInstanceId].map((iteration, index) =>
															<TableCell className={classes.nonSticky} key={`header-Iteration${index + 1}`} align="center">
																{taskInstanceDataVsOpen[taskInstanceId + "-headers"] &&
																	<IterationValues classes={classes} taskInstanceId={taskInstanceId} taskId={task._id} updateIterationValues={updateIterationValues} values={iteration["headers"]} index={index} type={"headers"} testVariables={testVariables} />
																}
															</TableCell>)}
													</TableRow>
													{/** Form Parameters */}
													<TableRow>
														<TableCell className={classes.sticky}>
															<TaskDefinition classes={classes} type={"Form Parameters"} data={task.formParameters} task={task}
																isOpen={taskInstanceDataVsOpen[taskInstanceId + "-formparams"]}
																handleOpen={v => setTaskInstanceDataVsOpen({ ...taskInstanceDataVsOpen, [taskInstanceId + "-formparams"]: v })} />
														</TableCell>
														{taskInstanceIdVsIterations[stage] && taskInstanceIdVsIterations[stage][taskInstanceId] && taskInstanceIdVsIterations[stage][taskInstanceId].map((iteration, index) =>
															<TableCell className={classes.nonSticky} key={`form-Iteration${index + 1}`} align="center">
																{taskInstanceDataVsOpen[taskInstanceId + "-formparams"] &&
																	<IterationValues classes={classes} taskInstanceId={taskInstanceId} taskId={task._id} updateIterationValues={updateIterationValues} values={iteration["formParameters"]} index={index} type={"formParameters"} testVariables={testVariables} />
																}
															</TableCell>)}
													</TableRow>
													{/** Query Parameters */}
													<TableRow>
														<TableCell className={classes.sticky}>
															<TaskDefinition classes={classes} type={"Query Parameters"} data={task.queryParameters} task={task}
																isOpen={taskInstanceDataVsOpen[taskInstanceId + "-queryparams"]}
																handleOpen={v => setTaskInstanceDataVsOpen({ ...taskInstanceDataVsOpen, [taskInstanceId + "-queryparams"]: v })} />
														</TableCell>
														{taskInstanceIdVsIterations[stage] && taskInstanceIdVsIterations[stage][taskInstanceId] && taskInstanceIdVsIterations[stage][taskInstanceId].map((iteration, index) =>
															<TableCell className={classes.nonSticky} key={`query-Iteration${index + 1}`} align="center">
																{taskInstanceDataVsOpen[taskInstanceId + "-queryparams"] &&
																	<IterationValues classes={classes} taskInstanceId={taskInstanceId} taskId={task._id} updateIterationValues={updateIterationValues} values={iteration["queryParameters"]} index={index} type={"queryParameters"} testVariables={testVariables} />
																}
															</TableCell>)}
													</TableRow>
													{/** BODY */}
													<TableRow>
														<TableCell className={classes.sticky}>
															<JsonBody classes={classes} type={"Body"} data={task.body}
																isOpen={taskInstanceDataVsOpen[taskInstanceId + "-body"]}
																handleOpen={v => setTaskInstanceDataVsOpen({ ...taskInstanceDataVsOpen, [taskInstanceId + "-body"]: v })} />
														</TableCell>
														{taskInstanceIdVsIterations[stage] && taskInstanceIdVsIterations[stage][taskInstanceId] && taskInstanceIdVsIterations[stage][taskInstanceId].map((iteration, index) =>
															<TableCell className={classes.nonSticky} key={`body-Iteration${index + 1}`} align="center">
																{taskInstanceDataVsOpen[taskInstanceId + "-body"] &&
																	<IterationBody label="Body" classes={classes} value={iteration["body"]} updateIterationBody={(v) => { updateIterationBody(taskInstanceId, index, "body", v) }} testVariables={testVariables} />
																}
															</TableCell>
														)}
													</TableRow>
													{/** RESPONSE */}
													<TableRow>
														<TableCell className={classes.sticky}>
															<JsonResponse classes={classes} type={"Response"} data=''
																isOpen={taskInstanceDataVsOpen[taskInstanceId + "-response"]}
																handleOpen={v => setTaskInstanceDataVsOpen({ ...taskInstanceDataVsOpen, [taskInstanceId + "-response"]: v })} />
														</TableCell>
														{taskInstanceIdVsIterations[stage] && taskInstanceIdVsIterations[stage][taskInstanceId] && taskInstanceIdVsIterations[stage][taskInstanceId].map((iteration, index) =>
															<TableCell className={classes.nonSticky} key={`body-Iteration${index + 1}`} align="center">
																{taskInstanceDataVsOpen[taskInstanceId + "-response"] &&
																	<IterationResponse label="Response" classes={classes}
																		updateResponseCode={(v) => { updateIterationBody(taskInstanceId, index, "responseCode", v) }}
																		value={iteration["response"]} responseCode={iteration["responseCode"]} updateIterationBody={(v) => { updateIterationBody(taskInstanceId, index, "response", v) }}
																		testVariables={testVariables} />
																}
															</TableCell>
														)}
													</TableRow>
												</>
											}
										</React.Fragment>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</Grid>
				</Grid>
			</>
			<br />
			<Divider />
			<div className={'pdl2'}>
				<Button color="primary" variant="contained" onClick={() => handleSave()} > Save </Button>
				<Button className={classes.mt} color="primary" variant="contained" onClick={() => handleTest()} > Test </Button>
			</div>
		</div>
	)
}

export default ManageAPITestsDataPage;