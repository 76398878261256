import React from 'react';
import {
	Checkbox,
	FormControl,
	IconButton,
	Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography
} from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import {
	KeyboardArrowDown as KeyboardArrowDownIcon,
	KeyboardArrowUp as KeyboardArrowUpIcon
} from '@material-ui/icons';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/styles';
import FunctionsProvider from 'components/commons/FunctionsProvider';
import MaterialTable from 'material-table';
import { useEffect, useState } from 'react';
import { generateRandomString } from './helpers/getInitials';
var _ = require('lodash');

let DEBUG_MODE = false;

const useStyles = makeStyles((theme) => ({
	tabs: {
		paddingLeft: 25
	},
	addButton: {
		marginRight: theme.spacing(1.5)
	},
	mt: {
		margin: theme.spacing(1)
	},
	table: {
		minWidth: 650,
	},
	sticky: {
		position: "sticky",
		width: 300,
		left: 0,
		background: "white",
		boxShadow: "5px 2px 5px #f1f4f5",
		zIndex: 10
	},
	nonSticky: {
		width: 300,
		overflowX: "auto",
	},
}))


function IterationValues({ taskInstanceId, taskId, index, type, values, classes, updateIterationValues, testVariables }) {
	return (
		<>
			{(values && values.length > 0) ?
				<TableContainer >
					<Table aria-label="simple table" >
						<TableHead >
							<TableRow>
								<TableCell align="left" style={{ background: 'white', fontWeight: '400' }}>{type}</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{values.map((header, headerIndex) => (
								<TableRow key={`value-Iteration${header.name}-${headerIndex}`}>
									<TableCell align="left">
										<div style={{ display: 'flex', alignItems: 'center' }}>
											<TextField
												fullWidth
												label={`${header.name}`}
												margin="dense"
												name="NA"
												required
												variant="outlined"
												type="text"
												value={header.value || ''}
												onChange={(e) => updateIterationValues(taskInstanceId, index, type, headerIndex, e.target.value)} />

											<FunctionsProvider
												onFunctionUpdate={s => updateIterationValues(taskInstanceId, index, type, headerIndex, ((header.value || '') + s))}
												isResponse={false}
												testVariables={testVariables} />
										</div>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
				:
				<Typography variant="body1">{"N/A"}</Typography>
			}
		</>
	)
}

function IterationValuesUI({ taskInstanceId, taskId, index, type, values, classes, updateIterationValues, testVariables }) {
	return (
		<>
			{(values != null && values.length > 0) ?
				<TableContainer >
					<Table aria-label="simple table" >
						<TableHead >
							<TableRow>
								<TableCell align="left" style={{ background: 'white', fontWeight: '400' }}>{type}</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{values.map((header, headerIndex) => (
								<TableRow key={`value-Iteration${header.name}-${headerIndex}`}>
									<TableCell align="left">
										<div style={{ display: 'flex', alignItems: 'center' }}>
											<TextField
												fullWidth
												label={`${header.name}`}
												margin="dense"
												name="NA"
												required
												variant="outlined"
												type="text"
												value={header.value || ''}
												onChange={(e) => updateIterationValues(taskInstanceId, index, type, headerIndex, e.target.value)} />

											<FunctionsProvider
												onFunctionUpdate={s => updateIterationValues(taskInstanceId, index, type, headerIndex, ((header.value || '') + s))}
												isResponse={false}
												testVariables={testVariables} />
										</div>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
				:
				<Typography variant="body1">{"N/A"}</Typography>
			}
		</>
	)
}

function TaskDefinition({ task, type, data, classes, isOpen, handleOpen }) {
	return (
		<TableContainer style={{ maxWidth: "90%", /*border: "1px solid gray"*/ }}>
			<Table className={classes.table} aria-label="simple table" style={{ tableLayout: "fixed" }}>
				<TableHead >
					<TableRow>
						<TableCell align="left" className={classes.sticky} style={{ fontWeight: '400' }}>
							<IconButton size="small" onClick={() => handleOpen(!isOpen)}>
								{isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
							</IconButton>
							{` ${type}`}
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{isOpen &&
						<>
							{(!data || data.length === 0) &&
								<TableRow >
									<TableCell align="left" className={classes.sticky}>{"No Data"}</TableCell>
								</TableRow>
							}
							{data && data.map((header, index) => (
								<TableRow key={`header-Iteration${header.name}-${index}`} >
									<TableCell align="left" className={classes.sticky} >
										<Typography variant="body1" gutterBottom style={{ padding: '10px 2px' }}>
											{header.name}
										</Typography>
									</TableCell>
								</TableRow>
							))}
						</>
					}
				</TableBody>
			</Table>
		</TableContainer>
	)
}

function TaskDefinitionUI({ task, type, data, classes, isOpen, handleOpen }) {
	return (
		<TableContainer style={{ maxWidth: "90%", /*border: "1px solid gray"*/ }}>
			<Table className={classes.table} aria-label="simple table" style={{ tableLayout: "fixed" }}>
				<TableHead >
					<TableRow>
						<TableCell align="left" className={classes.sticky} style={{ fontWeight: '400' }}>
							{(data && data.length > 0) ?
								<IconButton size="small" onClick={() => handleOpen(!isOpen)}>
									{isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
								</IconButton>
								:
								<span style={{ paddingLeft: '32px' }}></span>
							}
							{` ${type}`}
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{isOpen &&
						<>
							{(!data || data.length === 0) &&
								<TableRow >
									<TableCell align="left" className={classes.sticky}>{"No Data"}</TableCell>
								</TableRow>
							}
							{data && data.map((header, index) => (
								<TableRow key={`header-Iteration${header.name}-${index}`} >
									<TableCell align="left" className={classes.sticky} >
										<Typography variant="body1" gutterBottom style={{ padding: '10px 2px', paddingLeft: '22px' }}>
											{header + (DEBUG_MODE ? ' ( Date Element ) ' : '')}
										</Typography>
									</TableCell>
								</TableRow>
							))}
						</>
					}
				</TableBody>
			</Table>
		</TableContainer>
	)
}

function JsonBody({ type, data, classes, isOpen, handleOpen }) {
	return (
		<>
			<IconButton size="small" onClick={() => handleOpen(!isOpen)}>
				{isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
			</IconButton>
			{type}
			{isOpen &&
				<div className={'pdt10'}>
					<TextField disabled fullWidth id="outlined-multiline-static-body" label={''} name="body" multiline rows="15" variant="outlined"
						value={data} />
				</div>
			}
		</>
	)
}

function JsonResponse({ type, data, classes, isOpen, handleOpen }) {
	return (
		<>
			<IconButton size="small" onClick={() => handleOpen(!isOpen)}>
				{isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
			</IconButton>
			{type}
			{isOpen &&
				<div className={'pdt10'}>
					<Typography variant="body1" gutterBottom style={{ padding: '10px 2px' }}>
						{'Response Code'}
					</Typography>
					<TextField disabled fullWidth id="outlined-multiline-static-body" label={''} name="body" multiline rows="15" variant="outlined"
						value={data} />
				</div>
			}
		</>
	)
}

function IterationResponse({ label, value, updateIterationBody, classes, responseCode, updateResponseCode, testVariables }) {
	return (
		<>
			<br />
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<TextField fullWidth label={`Response Code`} margin="dense" name="NA" variant="outlined" type="text"
					value={responseCode || ''} onChange={e => updateResponseCode(e.target.value)} />
				<FunctionsProvider key={'22j3vnhh'} onFunctionUpdate={s => updateResponseCode((responseCode || '') + s)} isResponse={true} testVariables={testVariables} />
			</div>
			<br />
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<TextField fullWidth label={label} name="body" multiline rows="15" variant="outlined"
					value={value} onChange={e => updateIterationBody(e.target.value)} />
				<FunctionsProvider key={'adksqq2b'} onFunctionUpdate={s => updateIterationBody(value + s)} isResponse={true} testVariables={testVariables} />
			</div>
		</>
	)
}

function IterationBody({ label, value, updateIterationBody, classes, testVariables }) {
	return (
		<>
			<br /><br />
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<TextField fullWidth label={label} name="body" multiline rows="15" variant="outlined"
					value={value} onChange={e => updateIterationBody(e.target.value)} />
				<FunctionsProvider onFunctionUpdate={s => updateIterationBody(value + s)} testVariables={testVariables} />
			</div>
		</>
	)
}

function IterationBodyUI({ label, value, updateIterationBody, classes, testVariables }) {
	return (
		<>
			<div style={{ display: 'flex', alignItems: 'center', paddingTop: '30px' }}>
				<TextField fullWidth label={label} name="body" multiline rows="1" variant="outlined"
					value={value} onChange={e => updateIterationBody(e.target.value)} />
				<FunctionsProvider onFunctionUpdate={s => updateIterationBody(value + s)} testVariables={testVariables} />
			</div>
		</>
	)
}


function VariablesComponent({ classes, testVariables, setTestVariables, }) {
	const [columns, setColumns] = useState([]);

	useEffect(() => {
		updateVariablesColumns(testVariables);
	}, [testVariables])

	const updateVariablesColumns = (variables) => {
		setColumns([
			{
				title: 'Variable', field: 'name', validate: rowData => !(rowData?.name?.indexOf(' ') >= 0),
				editComponent: props => {
					return (<div style={{ display: 'flex', alignItems: 'center' }}>
						<TextField label={"Name"} name="variable-name" variant="outlined"
							value={props.value}
							onChange={e => props.onChange(e.target.value)} />
					</div>);
				}
			},
			{
				title: 'Value', field: 'value', validate: rowData => rowData?.value?.length > 0,
				editComponent: props => {
					return (<div style={{ display: 'flex', alignItems: 'center' }}>
						<TextField label={"Value"} name="variable-value" variant="outlined"
							value={props.value}
							onChange={e => props.onChange(e.target.value)} />
						<FunctionsProvider key={'adksqq2b'} testVariables={variables} />
					</div>);
				}
			},
			{
				title: 'Iteration Level', field: 'iteration',
				editComponent: props => {
					const isIterationUndefined = typeof (props.rowData.iteration) == "undefined";
					return (<FormControl variant="outlined">
						<Checkbox color="primary" name="iteration" checked={props.rowData.iteration || isIterationUndefined ? true : false} onChange={e => props.onChange(e.target.checked)} />
					</FormControl>);
				}
			},
			// {
			// 	title: 'Mask', field: 'masked',
			// 	editComponent: props => {
			// 		const isMaskUndefined = typeof (props.rowData.masked) == "undefined";
			// 		return (<FormControl variant="outlined">
			// 			<Checkbox color="primary" name="masked" checked={props.rowData.masked || isMaskUndefined ? true : false} onChange={e => props.onChange(e.target.checked)} />
			// 		</FormControl>);
			// 	}
			// }
		]);
	}

	const checkDuplicateVaraiableExist = (variableName, rowIndex) => {
		const variableFound = testVariables.filter((testVariable, i) => (testVariable.name === variableName && i != rowIndex));
		return variableFound.length ? true : false;
	}

	return (
		<Accordion style={{ width: '100%' }}>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls="panel1bh-content"
				id="panel1bh-header"
			>
				<Typography className={classes.heading}>Variables</Typography>
			</AccordionSummary>
			<AccordionDetails>
				<MaterialTable
					title="Variables"
					columns={columns}
					data={testVariables}
					options={{ actionsColumnIndex: -1 }}
					style={{ width: '100%' }}
					editable={{
						onRowAdd: newData =>
							new Promise((resolve, reject) => {
								setTimeout(() => {
									newData.iteration = typeof (newData.iteration) == 'undefined' ? true : newData.iteration
									// newData.masked = typeof (newData.masked) == 'undefined' ? true : newData.masked
									if (!checkDuplicateVaraiableExist(newData.name)) {
										if (!newData.iteration) {
											const isRandomString = newData.value?.trim().indexOf('${randomString(');
											const isRandomNumber = newData.value?.trim().indexOf('${randomNumber(');
											if (isRandomString > -1) {
												const randomStringLength = newData.value.trim()?.substring(15, newData.value?.trim().length - 2);
												const randomString = generateRandomString(randomStringLength);
												newData.value = randomString;
											} else if (isRandomNumber > -1) {
												const randomNumberRange = newData.value.trim()?.substring(15, newData.value?.trim().length - 2);
												const rangeArr = randomNumberRange?.split(',');
												if (rangeArr.length == 2) {
													const randomNumber = _.random(rangeArr[0], rangeArr[1]);
													newData.value = randomNumber;
												}
											}
											setTestVariables([...testVariables, newData]);
										} else {
											setTestVariables([...testVariables, newData]);
										}
										resolve();
									} else {
										alert("Variable already exists");
										reject();
									}
								}, 100);
							}),
						onRowUpdate: (newData, oldData) =>
							new Promise((resolve, reject) => {
								setTimeout(() => {
									const index = oldData.tableData.id;
									newData.iteration = typeof (newData.iteration) == 'undefined' ? true : newData.iteration
									// newData.masked = typeof (newData.masked) == 'undefined' ? true : newData.masked
									if (!checkDuplicateVaraiableExist(newData.name, index)) {
										if (!newData.iteration) {
											const isRandomString = newData.value?.trim().indexOf('${randomString(');
											const isRandomNumber = newData.value?.trim().indexOf('${randomNumber(');
											if (isRandomString > -1) {
												const randomStringLength = newData.value.trim()?.substring(15, newData.value?.trim().length - 2);
												const randomString = generateRandomString(randomStringLength);
												newData.value = randomString;
											} else if (isRandomNumber > -1) {
												const randomNumberRange = newData.value.trim()?.substring(15, newData.value?.trim().length - 2);
												const rangeArr = randomNumberRange?.split(',');
												if (rangeArr.length == 2) {
													const randomNumber = _.random(rangeArr[0], rangeArr[1]);
													newData.value = randomNumber;
												}
											}
											const dataUpdate = [...testVariables];
											dataUpdate[index] = newData;
											setTestVariables([...dataUpdate]);
										} else {
											const dataUpdate = [...testVariables];
											dataUpdate[index] = newData;
											setTestVariables([...dataUpdate]);
										}
										resolve();
									} else {
										alert("Variable already exists");
										reject();
									}
								}, 100)
							}),
						onRowDelete: oldData =>
							new Promise((resolve, reject) => {
								setTimeout(() => {
									const dataDelete = [...testVariables];
									const index = oldData.tableData.id;
									dataDelete.splice(index, 1);
									setTestVariables([...dataDelete]);
									resolve()
								}, 100)
							}),
					}}
				/>
			</AccordionDetails>
		</Accordion>
	)


}

export { IterationBody, IterationBodyUI, IterationResponse, IterationValues, IterationValuesUI, JsonBody, JsonResponse, TaskDefinition, TaskDefinitionUI, VariablesComponent };
