import {
	Button, Grid, Link,
	//IconButton,
	TextField,
	Typography
} from '@material-ui/core';
//import ArrowBackIcon from '@material-ui/icons/ArrowBack';
//import { Facebook as FacebookIcon, Google as GoogleIcon } from 'icons';
import Snackbar from '@material-ui/core/Snackbar';
import withStyles from '@material-ui/core/styles/withStyles';
import MuiAlert from '@material-ui/lab/Alert';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import validate from 'validate.js';
import { userLogin, userLogout } from '../../actions/userActions';



const schema = {
	email: {
		presence: { allowEmpty: false, message: 'is required' },
		email: true,
		length: {
			maximum: 64
		}
	},

};

const styles = theme => ({
	root: {
		backgroundColor: theme.palette.background.default,
		height: '100%'
	},
	grid: {
		height: '100%'
	},
	quoteContainer: {
		backgroundColor: 'white',
		[theme.breakpoints.down('md')]: {
			display: 'none'
		}
	},
	quote: {
		backgroundColor: theme.palette.neutral,
		height: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		//backgroundImage: 'url(/images/auth.jpg)',
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center'
	},
	quoteInner: {
		textAlign: 'center',
		flexBasis: '600px'
	},
	quoteText: {
		color: theme.palette.black,
		fontWeight: 300
	},
	name: {
		marginTop: theme.spacing(3),
		color: theme.palette.black
	},
	bio: {
		color: theme.palette.black
	},
	contentContainer: {},
	content: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column'
	},
	contentHeader: {
		display: 'flex',
		alignItems: 'center',
		paddingTop: theme.spacing(5),
		paddingBototm: theme.spacing(2),
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2)
	},
	logoImage: {
		marginLeft: theme.spacing(4)
	},
	contentBody: {
		flexGrow: 1,
		display: 'flex',
		alignItems: 'center',
		[theme.breakpoints.down('md')]: {
			justifyContent: 'center'
		}
	},
	form: {
		paddingLeft: 100,
		paddingRight: 100,
		paddingBottom: 125,
		flexBasis: 700,
		[theme.breakpoints.down('sm')]: {
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2)
		}
	},
	title: {
		marginTop: theme.spacing(3)
	},
	socialButtons: {
		marginTop: theme.spacing(3)
	},
	socialIcon: {
		marginRight: theme.spacing(1)
	},
	sugestion: {
		marginTop: theme.spacing(2)
	},
	textField: {
		marginTop: theme.spacing(2)
	},
	signInButton: {
		margin: theme.spacing(2, 0)
	}
});

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class ForgotPassword extends React.Component {
	state = {
		isValid: false,
		values: {},
		touched: {},
		errors: {},
		alerts: {
			loginFailed: false,
			loginFailedMessage: '',
		}
	}

	hasError = field => {
		return this.state.touched[field] && this.state.errors[field] ? true : false;
	}

	handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		this.setState({
			alerts: {
				...this.state.alerts,
				loginFailed: false,
				loginFailedMessage: ''
			}
		})
	};

	handleChange = event => {
		event.persist();
		this.setState({
			values: {
				...this.state.values,
				[event.target.name]: event.target.type === 'checkbox' ? event.target.checked : event.target.value,
			},
			touched: {
				...this.state.touched,
				[event.target.name]: true
			}
		}, () => {

			const errors = validate(this.state.values, schema);
			this.setState({
				isValid: errors ? false : true,
				errors: errors || {}
			})
		})
	};

	handleSignIn = async event => {
		event.preventDefault();

		let res = await fetch('/api/users/forgotpassword', {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				...this.state.values
			})
		});
		let json = await res.json();
		if (json.status === 'success') {
			alert('Your password has been reset.\nPlease check your email for new password');
			this.props.history.push('/sign-in');
		} else {
			this.setState({
				alerts: {
					...this.state.alerts,
					loginFailed: true,
					loginFailedMessage: 'Unable to reset password!',
				}
			})
		}

	};

	render() {
		const { classes } = this.props;

		return (
			<>
				<div className={classes.root}>
					<Grid
						className={classes.grid}
						container
					>
						<Grid
							className={classes.quoteContainer}
							item
							lg={5}
						>
							<div className={classes.quote}>
								<div className={classes.quoteInner}>
									<Typography
										className={classes.quoteText}
										variant="h1"
									>
										Speed up your automation by 25%
                </Typography>
									<div className={classes.person}>
										<Typography
											className={classes.name}
											variant="body1"
										>
											Maintain your testcases like never before, projects, access control and much more.
                  </Typography>

									</div>
								</div>
							</div>
						</Grid>
						<Grid
							className={classes.content}
							item
							lg={7}
							xs={12}
						>
							<div className={classes.content}>
								<div className={classes.contentHeader}>
									{/* <IconButton onClick={handleBack}>
                  <ArrowBackIcon />
                </IconButton> */}
								</div>
								<div className={classes.contentBody}>
									<form
										className={classes.form}
										onSubmit={this.handleSignIn}
									>
										<Typography
											className={classes.title}
											variant="h2"
										>
											Reset Password
                  </Typography>
										<Grid
											className={classes.socialButtons}
											container
											spacing={2}
										>
										</Grid>
										<TextField
											className={classes.textField}
											error={this.hasError('email')}
											fullWidth
											helperText={
												this.hasError('email') ? this.state.errors.email[0] : null
											}
											label="Email address"
											name="email"
											onChange={this.handleChange}
											type="text"
											value={this.state.values.email || ''}
											variant="outlined"
										/>

										<Button
											className={classes.signInButton}
											color="primary"
											disabled={!this.state.isValid}
											fullWidth
											size="large"
											type="submit"
											variant="contained"
										>
											Reset Password
                  						</Button>
										<Typography
											color="textSecondary"
											variant="body1"
										>
											Don't have an account?{' '}
											<Link
												component={RouterLink}
												to="/sign-up"
												variant="h6"
											>
												Sign up
                    						</Link>
										</Typography>
									</form>
								</div>
							</div>
						</Grid>
					</Grid>
				</div>

				{/** alerts */}
				<Snackbar open={this.state.alerts.loginFailed} autoHideDuration={6000} onClose={this.handleClose}>
					<Alert onClose={this.handleClose} severity="error">
						{this.state.alerts.loginFailedMessage}
					</Alert>
				</Snackbar>

			</>
		);
	}

}


ForgotPassword.propTypes = {
	history: PropTypes.object
};


const mapStateToProps = state => ({
	version: state.user.version
});

export default connect(mapStateToProps, { userLogin, userLogout })(withStyles(styles)(ForgotPassword));
