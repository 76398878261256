import {
	Box, Card, CardActions, CardContent, CardHeader,
	Divider, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography
} from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import {
	AccountTree as AccountTreeIcon, ArrowBackRounded as ArrowBackRoundedIcon,
	Assessment as AssessmentIcon, Check as CheckIcon, Launch as LaunchIcon,
	Clear as ClearIcon, FormatListNumbered as FormatListNumberedIcon, KeyboardArrowDown as KeyboardArrowDownIcon,
	KeyboardArrowUp as KeyboardArrowUpIcon, ShowChart as ShowChartIcon
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import Tile from 'components/reports/Tile';
import fromNow from 'fromnow';
import MaterialTable from 'material-table';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from "react-router-dom";
import { get } from './components/commons/common-components';

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(2)
	},
	table: {
		minWidth: 250,
	},
	resultIcon: {
		float: 'right',
		paddingTop: 2,
		marginRight: 2
	},
	resultIconWithLink: {
		float: 'right',
		paddingTop: 2,
		marginRight: 2,
		cursor: 'pointer'
	},
	success_Failed: {
		textDecoration: "underline",
		color: "red",
	},
	success_Success: {
		textDecoration: "underline",
		color: "green",
	}


}))

const ReportsPage = props => {
	const classes = useStyles();
	const token = useSelector(state => state.user.token);
	let { jobId } = useParams();
	const [tableData, settTableData] = useState([]);
	const [jobData, setJobData] = useState(null);
	const [job, setJob] = useState(null);
	const [task, setTask] = useState(null);

	const history = useHistory();


	useEffect(() => {
		buildHome(jobId);
	}, [jobId])

	function handleIterationOpen(i) {
		let currentState = job.iterationVsOpen[i] || false;
		setJob({
			...job,
			iterationVsOpen: {
				...job.iterationVsOpen,
				["" + i]: !currentState
			}
		})
	}

	async function buildHome(jobId) {
		if (jobId) {
			let { job, rows } = await get(`/api/tests/reports/${jobId}`, token);
			setJobData(rows);

			for (let row of rows) {
				let key = "" + row.iterationNumber;
				if (!job.iterations.includes(key)) {
					job.iterations.push(key);
					job.iterationVsOpen[key] = false;
					job.iterationVsSuccess[key] = true;
				}
				if (!job.iterationVsTasks[key]) {
					job.iterationVsTasks[key] = []
				}
				job.iterationVsTasks[key].push(row);
				if (row && row.validationResult && row.validationResult.success === false) {
					job.iterationVsSuccess[key] = false;
				}
			}

			setJob(job);

		} else {
			history.push(`/reports`);
			let rows = await get(`/api/tests/reports`, token);
			settTableData(rows);
			setJobData(null); setJob(null);
		}
	}

	return (
		<div className={classes.root}>
			<Card>
				{job &&
					<CardHeader
						avatar={
							<IconButton size="small" aria-label="delete" onClick={() => { buildHome() }}>
								<ArrowBackRoundedIcon color="primary" />
							</IconButton>
						}
						title={`Report - ${job.name}`}
					/>
				}
				{!job && <CardHeader title={'View Reports'} />}

				<Divider />
				<CardContent>
					{!job &&
						<MaterialTable
							title=""
							columns={[
								{ title: 'Job Id', field: 'jobId' },
								{ title: 'Test Name', field: 'test.test.name' },
								{ title: 'Test Type', field: 'collection_name' },
								{
									title: "Executed On", field: "date",
									render: (rowData) => (
										<Tooltip title={rowData.date}>
											<span> {fromNow(new Date(rowData.date), { max: 1, suffix: true })} </span>
										</Tooltip>

									),
								},
								{ title: 'Status', field: 'success' },
							]}
							data={tableData}
							options={{
								pageSize: 10
								//selection: true
							}}
							editable={{}}
							onRowClick={(evt, selectedRow) => {
								if (selectedRow.collection_name === 'UI') {
									history.push(`/reports-ui/${selectedRow.jobId}`)
									history.go();
								} else {
									history.push(`/reports/${selectedRow.jobId}`)
									history.go();
								}
							}}
						/>}
					{job &&
						<>
							<Grid container spacing={4}>
								<Grid item lg={3} sm={6} xl={3} xs={12} >
									<Tile
										titlename={"Test"}
										titlevalue={job.name}
										footerpercent={-1}
										footervalue={`Report ID: ${job._id} ran ${fromNow(new Date(job.date), { max: 1, suffix: true })}`}
										icon={<AssessmentIcon />}
									/>
								</Grid>
								<Grid item lg={3} sm={6} xl={3} xs={12} >
									<Tile
										titlename={"Total Iterations"}
										titlevalue={job.iterationsCount}
										footerpercent={-1}
										footervalue={`Total iterations in the test`}
										icon={<FormatListNumberedIcon />}
									/>
								</Grid>
								<Grid item lg={3} sm={6} xl={3} xs={12} >
									<Tile
										titlename={"Tasks per iteration"}
										titlevalue={job.tasksPerIteration}
										footerpercent={-1}
										footervalue={`Number of tasks in single iteration`}
										icon={<AccountTreeIcon />}
									/>
								</Grid>
								<Grid item lg={3} sm={6} xl={3} xs={12} >
									<Tile
										titlename={"Total Tasks"}
										titlevalue={job.totalTasksCount}
										footerpercent={-1}
										footervalue={`${job.iterationsCount} iteration(s) X ${3} tasks`}
										icon={<ShowChartIcon />}
									/>
								</Grid>
							</Grid>
							<br /><br />
							<Box ml={1}>
								<Typography variant="h3" gutterBottom>
									{` Status: `} <span className={classes[`success_${job.success}`]}>{job.success}</span>
								</Typography>
							</Box>
							<br />
							<Box ml={1}>
								<Typography variant="h3" gutterBottom>
									{` Test Results`}
								</Typography>
							</Box>
							<br />

							<Grid container spacing={4}>
								<Grid item xs={6}>
									<TableContainer component={Paper}>
										<Table className={classes.table} style={{ tableLayout: "fixed" }}>

											<TableHead>
												<TableRow>
													<TableCell className={classes.sticky} style={{ backgroundColor: '#fafafa' }}> {'Test Iterations'} </TableCell>
												</TableRow>
											</TableHead>
											<TableBody>

												{job.iterations.map((ele, index) =>
													<React.Fragment key={`col-Iteration${ele}`}>
														<TableRow >
															<TableCell className={classes.nonSticky} align="left">
																<IconButton size="small" onClick={() => handleIterationOpen(ele)}>
																	{job.iterationVsOpen["" + ele] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
																</IconButton>
																{`Iteration-${ele}`}
																{job.iterationVsSuccess[ele] === true &&
																	<CheckIcon style={{ color: green[500] }} className={classes.resultIcon} />
																}
																{job.iterationVsSuccess[ele] === false &&
																	<ClearIcon style={{ color: red[500] }} className={classes.resultIcon} />
																}
																{/* <IconButton onClick={() => removeIteration(index)}> <DeleteIcon fontSize="small" /> </IconButton> */}
															</TableCell>
														</TableRow>

														{job.iterationVsOpen["" + ele] &&
															job.iterationVsTasks["" + ele].map((taskEle, taskIndex) =>
																<TableRow key={`${ele}_${taskEle._id}`} hover onClick={() => { setTask(taskEle) }}>
																	<TableCell className={classes.nonSticky} align="left">
																		<Box ml={4}>
																			{`${taskEle.task.name}`}

																			{taskEle.validationResult && taskEle.validationResult.success === true &&
																				<CheckIcon style={{ color: green[500] }} className={classes.resultIcon} />
																			}
																			{taskEle.validationResult && taskEle.validationResult.success === false &&
																				<ClearIcon style={{ color: red[500] }} className={classes.resultIcon} />
																			}
																			{taskEle.result && taskEle.result.apiCallId &&
																				<LaunchIcon color="action" className={classes.resultIconWithLink}
																					onClick={() => {
																						window.open(`/api-trace/${taskEle.result.apiCallId}`, "_blank")
																					}} />
																			}
																		</Box>
																	</TableCell>
																</TableRow>
															)
														}
													</React.Fragment>
												)}
											</TableBody>
										</Table>

									</TableContainer>

								</Grid>
								{/** Test Result Area */}
								<Grid item xs={6}>

									<TableContainer component={Paper}>
										<Table className={classes.table} style={{ tableLayout: "fixed" }}>

											<TableHead>
												<TableRow>
													<TableCell className={classes.sticky} style={{ backgroundColor: '#fafafa' }}> {'Expected  '} </TableCell>
												</TableRow>
											</TableHead>

											<TableBody>
												<TableRow >
													<TableCell className={classes.nonSticky} align="left">
														{task &&
															<Box>
																<p>Response Code: {task.iteration.responseCode} </p>
																<p>Body:  </p>
																<pre> {JSON.stringify(task.iteration.response, null, 2)}</pre>
															</Box>
														}
														{!task &&
															'Select a test to see details'
														}
													</TableCell>
												</TableRow>

											</TableBody>

											<TableHead> <TableRow>
												<TableCell className={classes.sticky} style={{ backgroundColor: '#fafafa' }}> {'Actual  '} </TableCell>
											</TableRow> </TableHead>

											<TableBody>
												<TableRow >
													<TableCell className={classes.nonSticky} align="left">
														{task &&
															<Box>
																<p>Response Code: {task.result.responseCode} </p>
																<p>Body:  </p>
																<pre> {JSON.stringify(task.result.response, null, 2)}</pre>
															</Box>
														}
														{!task &&
															'Select a test to see details'
														}
													</TableCell>
												</TableRow>
											</TableBody>
											<TableHead> <TableRow>
												<TableCell className={classes.sticky} style={{ backgroundColor: '#fafafa' }}> {'Result  '} </TableCell>
											</TableRow> </TableHead>
											<TableBody>
												<TableRow >
													<TableCell className={classes.nonSticky} align="left">
														{task &&
															<Box>
																<p>{`Success: ${task.validationResult.success}`}</p>
																<p>{`Message: ${task.validationResult.message}`}</p>
															</Box>
														}
														{!task &&
															'Select a test to see details'
														}
													</TableCell>
												</TableRow>
											</TableBody>
										</Table>
									</TableContainer>
								</Grid>
							</Grid>
						</>
					}
				</CardContent>
				<Divider />
				<CardActions >
				</CardActions>
			</Card>
		</div >
	)

}

export default ReportsPage;
