import {
	Button, Card, CardContent, CardHeader, Divider, Grid,
	IconButton, Paper, Tab, Tabs, TextField, Typography
} from '@material-ui/core';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import { makeStyles } from '@material-ui/styles';
import KeyValBuilder from 'components/KeyValBuilder';
import MaterialTable from 'material-table';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AntTab, AntTabs } from './components/Ants';
import { get, post, del } from './components/commons/common-components';
import { useHistory, useParams } from "react-router-dom";
import DropDownPlus from "views/Account/components/DropDownPlus";

const DEFAULT_API_ROW = {
	_id: null,
	name: '',
	method: 'GET',
	url: '',
	headers: [],
	formParameters: [],
	queryParameters: [],
}

const DEFAULT_UI_ROW = {
	_id: null,
	name: '',
	actions: [],
	options: {
		pages: [],
		actions: [],
		dataElements: [],
	},
}

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(2)
	},
	tabs: {
		paddingLeft: 25
	},
	cardHeader: {
		minHeight: theme.spacing(6.9)
	},
	addButton: {
		marginRight: theme.spacing(1.5)
	},
	rightPadding: {
		paddingRight: theme.spacing(1)
	},
	resultCard: {
		marginTop: theme.spacing(1.5),
		width: "100%",
		background: "#eaeaea",
	}
}))

const ManageTasksPage = props => {
	const classes = useStyles();
	const history = useHistory();
	const token = useSelector(state => state.user.token);
	let { taskId: taskIdFromParams } = useParams();
	const [pageVsNameVsDataElements, setPageVsNameVsDataElements] = useState({});
	const [activeTabValue, setActiveTabValue] = useState(0);
	const [requestTabIndex, setRequestTabIndex] = useState(0);
	const [uiTasks, setUiTasks] = useState([]);
	const [apiTasks, setApiTasks] = useState([]);
	const [values, setValues] = useState({});
	const [editMode, setEditMode] = useState(false);
	const [testResult, setTestResult] = useState({});
	const [taskType, setTaskType] = useState("ui");

	useEffect(() => {
		fetchPageVsNameVsDataElements();
	}, []);

	async function fetchPageVsNameVsDataElements() {
		let data = await get(`/api/tests/pageVsNameVsDataElements`, token);
		setPageVsNameVsDataElements(data);
	}

	useEffect(() => {
		resetData();
		fetchTasks();
	}, [])

	async function fetchTasks() {
		let data = await get(`/api/tasks`, token);
		let _ui = data.filter(ele => ele.type === 'ui');
		let _api = data.filter(ele => ele.type === 'api');
		setUiTasks(_ui);
		setApiTasks(_api);
		if (taskIdFromParams) {
			let rowData = data.filter(ele => ele._id === taskIdFromParams)[0];
			//console.log('rowData', rowData);
			if (rowData.type === 'api') {
				setActiveTabValue(1);
			}
			updateEditData(rowData);
			history.push('/tasks');
			//console.log('taskIdFromParams', taskIdFromParams);
		}
	}

	function resetData() {
		if (activeTabValue === 0) {
			setValues({ ...DEFAULT_UI_ROW });
		}
		if (activeTabValue === 1) {
			setRequestTabIndex(0)
			setValues({ ...DEFAULT_API_ROW });
		}
		setEditMode(false);
		setTestResult(null)
	}

	async function handleDelete(row) {
		if (window.confirm(`Are you sure you wish to delete the task?`)) {
			await del(`/api/tasks/${row._id}`, token, {});
			fetchTasks();
		}
	}

	async function addTask() {
		if (activeTabValue === 0) {
			setValues({ ...DEFAULT_UI_ROW });
		} else if (activeTabValue === 1) {
			setValues({ ...DEFAULT_API_ROW });
		}
		setEditMode(true);
	}

	async function handleTest() {
		var domain = prompt('Please enter domain name');
		// if (domain) {
		let res = await fetch('/api/tasks/testOneTask', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				domain,
				...values,
				"type": "api"
			})
		});
		let json = await res.json();
		//console.log(json)
		let data = json.response;
		if (data && data.trim().startsWith("{") || data.trim().startsWith("[")) { // json response
			let dataObj = JSON.parse(data);
			data = JSON.stringify(dataObj, null, 2);
		}

		//console.log(data)
		setTestResult({ status: json.responseCode, data });
		// }
	}
	async function handleSave() {
		let canSave = true;
		if (values._id) {
			canSave = window.confirm("This effects existing tests, that are using this task. Do you want to continue?");
		}
		if (!canSave) { return; }
		let type = (activeTabValue === 0) ? "ui" : "api";
		let json = await post('/api/tasks/', token, { ...values, "type": type });
		if (json.status === 'success') {
			//alert('Saved!');
			setValues({ ...values, _id: json.data._id })
			fetchTasks();
			resetData();
		} else {
			alert(json.message || 'Something went wrong!');
		}
	}

	async function updateEditData(rowData) {
		if (rowData.type === 'ui') {
			setValues({
				_id: rowData._id,
				name: rowData.name,
				actions: rowData.actions,
				options: rowData.options,
			});
		}
		if (rowData.type === 'api') {
			setValues({
				//...values,
				_id: rowData._id,
				name: rowData.name,
				method: rowData.method,
				url: rowData.url,
				body: rowData.body,
				headers: rowData.headers,
				formParameters: rowData.formParameters,
				queryParameters: rowData.queryParameters
			});
		}
		setEditMode(true);
	}

	async function onRowAdd(newData) {
		setValues({
			...values,
			actions: [
				...values.actions,
				{
					...newData,
					_id: Date.now()
				}]
		})
		// this.setState({
		// 	actions: [...this.state.actions, {
		// 		...newData,
		// 		_id: Date.now()
		// 	}]
		// });
	}

	async function onRowUpdate(newData, oldData) {
		setValues({
			...values,
			actions: [...values.actions.filter(ele => ele._id !== oldData._id), newData]
		})
		// this.setState({
		// 	actions: [...this.state.actions.filter(ele => ele._id !== oldData._id), newData]
		// })
	}

	async function onRowDelete(oldData) {
		setValues({
			...values,
			actions: [...values.actions.filter(ele => ele._id !== oldData._id)]
		})
		// this.setState({
		// 	actions: this.state.actions.filter(ele => ele._id !== oldData._id)
		// })
	}



	return (<div className={classes.root}>
		<Card>
			{!editMode &&
				<CardHeader
					className={classes.cardHeader}
					title={'Tasks'}
					action={<Button className={classes.addButton} color="primary" size="small" variant="outlined" onClick={() => addTask()}> New Task </Button>}
				/>
			}
			{editMode &&
				<CardHeader
					avatar={
						<IconButton size="small" aria-label="delete" onClick={() => { resetData() }}>
							<ArrowBackRoundedIcon color="primary" />
						</IconButton>
					}
					title={values._id ? 'Update Task' : 'Create Task'}
				/>
			}

			<Divider />
			<CardContent>
				<Paper className={classes.tabs}>
					<Tabs value={activeTabValue} indicatorColor="primary" textColor="primary"
						onChange={(event, newValue) => { setActiveTabValue(newValue); setEditMode(false); }} >
						<Tab label="UI" />
						<Tab label="API" />
					</Tabs>
				</Paper>
				<br />

				{activeTabValue === 0 && !editMode &&
					<Grid item md={12} xs={12} >
						<MaterialTable
							title=""
							options={{ actionsColumnIndex: -1, pageSize: 10 }}
							localization={{
								header: {
									actions: ''
								},
							}}
							columns={[
								{ title: 'Name', field: 'name' },
								// { title: 'Page', field: 'page' },
								// { title: 'Data Elements', field: 'dataElements', render: rowData => (rowData.dataElements || []).join(', ') },
							]}
							data={uiTasks}
							actions={[
								rowData => ({
									icon: "delete", tooltip: "Delete Rule",
									onClick: (event, rowData) => handleDelete(rowData),
								}),
							]}
							editable={{}}
							onRowClick={(event, rowData) => updateEditData(rowData)}
						/>
					</Grid>
				}

				{activeTabValue === 1 && !editMode &&
					<Grid item md={12} xs={12} >
						<MaterialTable
							title=""
							options={{ actionsColumnIndex: -1, pageSize: 10 }}
							localization={{
								header: {
									actions: ''
								},
							}}
							columns={[
								{ title: 'Name', field: 'name' },
								{ title: 'Endpoint', field: 'url' },
								{ title: 'Method', field: 'method' },
							]}
							data={apiTasks}
							actions={[
								rowData => ({
									icon: "delete", tooltip: "Delete Rule",
									onClick: (event, rowData) => handleDelete(rowData),
								}),
							]}
							editable={{}}
							onRowClick={(event, rowData) => updateEditData(rowData)}
						/>
					</Grid>
				}

				{activeTabValue === 0 && editMode &&
					<Grid container spacing={0}>

						<Grid item xs={5} >
							<div style={{ paddingTop: 5 }}></div>
							<TextField fullWidth label="Name" margin="dense" name="url" required variant="outlined" type="text"
								value={values.name || ''} onChange={e => setValues({ ...values, name: e.target.value })}
							/>
						</Grid>

						<Grid item xs={12} >
							<br />
							<MaterialTable
								title=""
								options={{ pageSize: 10, actionsColumnIndex: -1, search: false, }}
								localization={{ header: { actions: '' }, }}
								columns={[
									{
										title: 'Page', field: 'page',
										lookup: Object.keys(pageVsNameVsDataElements).reduce((map, ele) => {
											map[ele] = ele
											return map;
										}, {})
									},
									{
										title: 'Action', field: 'action',
										render: rowData => rowData.action,
										editComponent: props => {
											return <DropDownPlus
												placeholder={'Action'} label={'Action'} multi={false}
												options={(props.rowData.page) ? Object.keys(pageVsNameVsDataElements[props.rowData.page]) : []}
												values={props.value ? [props.value] : []}
												onChange={v => {
													props.onChange(v)
												}}
											/>
										},
									},
									{
										title: 'Data Elements', field: 'dataElement',
										render: rowData => (rowData.dataElement) ? rowData.dataElement.join(', ') : '',
										editComponent: props => {
											return <DropDownPlus
												placeholder={'Data Elements'} label={'Data Elements'} multi={true}
												values={props.value ? props.value : []}
												options={(props.rowData.page && props.rowData.action && pageVsNameVsDataElements[props.rowData.page]
													&& pageVsNameVsDataElements[props.rowData.page][props.rowData.action]) ?
													pageVsNameVsDataElements[props.rowData.page][props.rowData.action] : []}
												onChange={v => {
													props.onChange(v)
												}}
											/>
										},
									},
								]}
								data={values.actions.sort((e1, e2) => e1._id > e2._id ? 1 : -1)}
								editable={{
									isEditable: () => true,
									isDeletable: () => true,
									onRowAdd: onRowAdd,
									onRowUpdate: onRowUpdate,
									onRowDelete: onRowDelete,
								}}
							/>
							<br />
							<Button style={{ float: 'right' }} color="primary" variant="contained"
								onClick={() => handleSave()}
							> Save </Button>
						</Grid>

					</Grid>

				}

				{activeTabValue === 1 && editMode &&
					<>
						<Grid container spacing={0}>
							<Grid item xs={12} md={12} lg={12} >
								<TextField fullWidth label="Name" margin="dense" name="url" required variant="outlined" type="text"
									value={values.name || ''} onChange={e => setValues({ ...values, name: e.target.value })}
								/>
							</Grid>
							<Grid container spacing={0}>
								<Grid item xs={12} md={2} lg={2} >
									<TextField fullWidth label="Method" className={classes.rightPadding} margin="dense" name="method" required select variant="outlined"
										SelectProps={{ native: true }}
										value={values.method || 'GET'}
										onChange={(e) => setValues({ ...values, method: e.target.value })}
									> {['GET', 'POST', 'PUT', 'DELETE'].map(option => <option key={option} value={option} > {option} </option>)}
									</TextField>
								</Grid>
								<Grid item xs={12} md={10} lg={10} >
									<TextField fullWidth label="Endpoint" margin="dense" name="url" required variant="outlined" type="text"
										value={values.url || ''} onChange={(e) => setValues({ ...values, url: e.target.value })}
									/>
								</Grid>
							</Grid>
							<Grid item xs={12} md={12} lg={12} >
								<br />
								<AntTabs value={requestTabIndex} onChange={(e, newVal) => setRequestTabIndex(newVal)} aria-label="ant example">
									<AntTab label="Headers" />
									<AntTab label="Query Parameters" />
									<AntTab label="Form Parameters" />
									<AntTab label="Body" />
								</AntTabs>
							</Grid>
							{requestTabIndex === 0 &&
								<KeyValBuilder rows={values.headers}
									handleUpdate={newRows => setValues({ ...values, headers: newRows })} />
							}
							{requestTabIndex === 1 &&
								<KeyValBuilder rows={values.queryParameters}
									handleUpdate={newRows => setValues({ ...values, queryParameters: newRows })} />
							}
							{requestTabIndex === 2 &&
								<KeyValBuilder rows={values.formParameters}
									handleUpdate={newRows => setValues({ ...values, formParameters: newRows })} />
							}
							{requestTabIndex === 3 &&
								<TextField fullWidth id="outlined-multiline-static-body" label="Request Body" name="body" margin="dense" multiline rows="15" variant="outlined"
									value={values.body || ''} onChange={(e) => setValues({ ...values, body: e.target.value })}
								/>
							}
							{testResult &&
								<Grid item xs={12} md={12} lg={12} >
									<br /> <Divider /> <br />
									<Typography variant="h5" gutterBottom> Response: </Typography>
									<Typography variant="body2" gutterBottom> {`Status Code: ${testResult.status}`} </Typography>
									<Card className={classes.resultCard}> <pre className={classes.root}> {testResult.data} </pre> </Card>
								</Grid>
							}
						</Grid>
						<br /><br />
						<Grid container>
							<Grid item xs={6} md={1} lg={1} >
								<Button disabled={!values.name || !values.url}
									color="primary" variant="contained" onClick={() => handleSave()} > Save </Button>
							</Grid>
							<Grid item xs={6} md={1} lg={1} >
								<Button disabled={!values.name || !values.url} color="primary" variant="contained"
									onClick={() => handleTest()}
								> Test </Button>
							</Grid>
						</Grid>
					</>
				}
			</CardContent>
		</Card>


	</div>)

}

export default ManageTasksPage;



