/* eslint-disable react/prop-types, react/jsx-handler-names */

import Chip from "@material-ui/core/Chip";
import MenuItem from "@material-ui/core/MenuItem";
import NoSsr from "@material-ui/core/NoSsr";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import { emphasize } from "@material-ui/core/styles/colorManipulator";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import CancelIcon from "@material-ui/icons/Cancel";
import EditIcon from '@material-ui/icons/Edit';
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import Select from 'react-select';
//import Select from "react-select";
import CreatableSelect from "react-select/lib/Creatable";
import FormHelperText from '@material-ui/core/FormHelperText';
/*
const suggestions = [
  { label: "Afghanistan" },
  { label: "Aland Islands" },
].map(suggestion => ({
  value: suggestion.label,
  label: suggestion.label
}));
*/

const MAX_MENU_HEIGHT = 188;

const styles = theme => ({
	root: {
		flexGrow: 1,
		//height: 250,
	},
	input: {
		display: 'flex',
		padding: 0,
		height: 'auto',
	},
	valueContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		flex: 1,
		alignItems: 'center',
		overflow: 'hidden',
	},
	chip: {
		margin: theme.spacing(0.5, 0.25),
	},
	chipFocused: {
		backgroundColor: emphasize(
			theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
			0.08,
		),
	},
	noOptionsMessage: {
		padding: theme.spacing(1, 2),
	},
	singleValue: {
		fontSize: 16,
	},
	placeholder: {
		position: 'absolute',
		left: 2,
		bottom: 6,
		fontSize: 16,
	},
	paper: {
		position: 'absolute',
		zIndex: 11,
		marginTop: theme.spacing(1),
		left: 0,
		right: 0,
	},
	divider: {
		height: theme.spacing(0),
	},
});

function NoOptionsMessage(props) {
	return (
		<Typography
			color="textSecondary"
			className={props.selectProps.classes.noOptionsMessage}
			{...props.innerProps}
		>
			{props.children}
		</Typography>
	);
}

function inputComponent({ inputRef, ...props }) {
	return <div ref={inputRef} {...props} />;
}

function Control(props) {
	// console.log('propsprops', props.selectProps.errorTextVal);
	return (
		<TextField

			error={(props.selectProps.errorTextVal) ? true : false}
			helperText={props.selectProps.errorTextVal || null}
			disabled={props.selectProps.isDisabled || false}
			fullWidth
			InputProps={{
				inputComponent,
				inputProps: {
					className: props.selectProps.classes.input,
					inputRef: props.innerRef,
					children: props.children,
					...props.innerProps
				}
			}}
			{...props.selectProps.textFieldProps}
		/>
	);
}

function Option(props) {
	return (
		<MenuItem
			buttonRef={props.innerRef}
			selected={props.isFocused}
			component="div"
			style={{
				fontWeight: props.isSelected ? 500 : 400,
				zIndex: 999
			}}
			{...props.innerProps}
		>
			{props.children}
		</MenuItem>
	);
}

function Placeholder(props) {
	return (
		<Typography
			color="textSecondary"
			className={props.selectProps.classes.placeholder}
			{...props.innerProps}
		>
			{props.children}
		</Typography>
	);
}

function SingleValue(props) {
	return (
		<>
			{
				(props.selectProps.rename) && <EditIcon className="pointer" onClick={(e) => {
					let renameMessage = props.selectProps.renameMessage || 'Are you sure want to rename?'
					let currentValue = props.children;

					// eslint-disable-next-line no-restricted-globals
					if (confirm(renameMessage)) {
						// console.log(props);
						if (currentValue && props.selectProps && props.selectProps.onRename) {
							props.selectProps.onRename(currentValue);
						}
					}
				}} />
			}
			<Typography
				className={props.selectProps.classes.singleValue}
				{...props.innerProps}
			>
				{props.children}
			</Typography>
		</>
	);
}

function ValueContainer(props) {
	return (
		<div className={props.selectProps.classes.valueContainer}>
			{props.children}
		</div>
	);
}

function MultiValue(props) {
	return (
		<Chip
			icon={(props.selectProps.rename) ? <EditIcon className="pointer" onClick={(e) => {
				let renameMessage = props.selectProps.renameMessage || 'Are you sure want to rename?'
				let currentValue = props.children;

				// eslint-disable-next-line no-restricted-globals
				if (confirm(renameMessage)) {
					// console.log(props);
					if (currentValue && props.selectProps && props.selectProps.onRename) {
						props.selectProps.onRename(currentValue);
					}
				}


			}} /> : null}
			tabIndex={-1}
			label={props.children}
			className={classNames(props.selectProps.classes.chip, {
				[props.selectProps.classes.chipFocused]: props.isFocused
			})}
			onDelete={() => {
				if (props.selectProps.deleteMessage) {
					// eslint-disable-next-line no-restricted-globals
					if (confirm(props.selectProps.deleteMessage)) {
						props.removeProps.onClick();
					} else {
						//nothing
					}
				} else {
					props.removeProps.onClick();
				}
			}}
			deleteIcon={<CancelIcon {...props.removeProps} />}
		/>
	);
}

function Menu(props) {
	return (
		<Paper
			square
			className={props.selectProps.classes.paper}
			{...props.innerProps}
		>
			{props.children}
		</Paper>
	);
}

const components = {
	Control,
	Menu,
	MultiValue,
	NoOptionsMessage,
	Option,
	Placeholder,
	SingleValue,
	ValueContainer
};

class IntegrationReactSelect extends React.Component {
	state = {
		single: null,
		multi: null
	};

	handleChange = value => {
		//this.setState({
		//[name]: value
		//});
		if (this.props.multi) { // arrays
			if (value) {
				this.props.onChange(value.map(ele => ele.value));
			} else {
				this.props.onChange([]);
			}
		} else { // values
			if (value) {
				this.props.onChange(value.value);
			} else {
				this.props.onChange(null);
			}
		}
	};


	/*
	componentWillReceiveProps(nextProps){
	  // console.log('componentWillReceiveProps');
	  if(this.props.multi){
		if(nextProps.values && nextProps.values.length > 0){
		  this.setState({
			...this.state,
			multi: nextProps.values.map(ele => ({label: ele, value: ele}))
		  });
		}else{
		  this.setState({ ...this.state, multi: null });
		}
	  }else{
		if(nextProps.values){
		  this.setState({
			...this.state,
			multi:  [{ label:  nextProps.values, value: nextProps.values}]
		  });
		}else{
		  this.setState({ ...this.state, multi: null });
		}
	  }
	}
	*/

	listToValues = (vals) => {
		if (vals) {
			return vals.map(ele => ({ label: ele, value: ele }));
		} else {
			return [];
		}
	};

	/*
	componentDidMount(){
	  if(this.props.multi){
		// console.log('this.props.values', this.props.values);
		  // console.log('this.props.multi', this.props.multi);
		if(this.props.values && this.props.values.length > 0){
		  
		  this.setState({
			...this.state,
			multi: this.props.values.map(ele => ({label: ele, value: ele}))
		  });
		}
	  }else{
	  }
	}*/

	renameExistingValue = v => {
		var newVal = prompt("provide new value. Note: This rename all occurrences", v);
		if (newVal != null) {
			// console.log('new val:' +  newVal);
			this.props.onRename(v, newVal);
		}
	}

	render() {
		const { classes, theme } = this.props;

		const selectStyles = {
			menu: styles => ({ ...styles, zIndex: 999 }),
			input: base => ({
				...base,
				color: theme.palette.text.primary,
				"& input": {
					font: "inherit"
				}
			}),

		};


		let options = this.props.options.sort();
		options = options.map(ele => ({ value: ele, label: ele }));


		return (
			<div className={classes.root}>
				<NoSsr>
					<div className={classes.divider} />
					{this.props.create &&
						<CreatableSelect
							maxMenuHeight={MAX_MENU_HEIGHT}
							classes={classes}
							styles={selectStyles}
							textFieldProps={{
								label: this.props.label,
								InputLabelProps: {
									shrink: true
								}
							}}
							errorTextVal={this.props.errorTextVal}
							isDisabled={this.props.readonly || false}
							options={options}
							rename={this.props.rename}
							renameMessage={this.props.renameMessage}
							deleteMessage={this.props.deleteMessage}
							onRename={this.renameExistingValue}
							components={components}
							value={this.listToValues(this.props.values)}
							onChange={this.handleChange}
							placeholder={this.props.placeholder}
							isClearable
							isMulti={this.props.multi}
						/>
					}

					{!this.props.create &&
						<>
							<Select
								maxMenuHeight={MAX_MENU_HEIGHT}
								classes={classes}
								styles={selectStyles}
								textFieldProps={{
									label: this.props.label,
									InputLabelProps: {
										shrink: true
									}
								}}
								errorTextVal={this.props.errorTextVal}
								isDisabled={this.props.readonly || false}
								options={options}
								components={components}
								value={this.listToValues(this.props.values)}
								onChange={this.handleChange}
								placeholder={this.props.placeholder}
								isClearable
								isMulti={this.props.multi}
							/>
							<FormHelperText>{this.props.formHelperText}</FormHelperText>
						</>
					}

				</NoSsr>
			</div>
		);
	}
}

IntegrationReactSelect.propTypes = {
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(IntegrationReactSelect);
