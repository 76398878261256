import uuid from 'uuid/v1';

export default [
  {
    id: uuid(),
    ref: 'TEST1049',
    amount: 30.5,
    customer: {
      name: 'Login and Logout test'
    },
    createdAt: 1555016400000,
    status: 'Dropbox'
  },
  {
    id: uuid(),
    ref: 'TEST1048',
    amount: 25.1,
    customer: {
      name: 'Invalid credentials test'
    },
    createdAt: 1555016400000,
    status: 'Medium'
  },
  {
    id: uuid(),
    ref: 'TEST1047',
    amount: 10.99,
    customer: {
      name: 'Mobile view test'
    },
    createdAt: 1554930000000,
    status: 'Lyft'
  },
  {
    id: uuid(),
    ref: 'TEST1046',
    amount: 96.43,
    customer: {
      name: 'Performance test'
    },
    createdAt: 1554757200000,
    status: 'Dropbox'
  },
  {
    id: uuid(),
    ref: 'TEST1045',
    amount: 32.54,
    customer: {
      name: 'Misc test'
    },
    createdAt: 1554670800000,
    status: 'Medium'
  },
  {
    id: uuid(),
    ref: 'TEST1044',
    amount: 16.76,
    customer: {
      name: 'Rgression test'
    },
    createdAt: 1554670800000,
    status: 'Medium'
  }
];
