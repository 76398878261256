import {
    Button, Card, CardActions, CardContent, CardHeader, Divider,
    Grid, TextField
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { withStyles } from "@material-ui/core/styles";
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';
import React, { Component } from "react";
import DropDownPlus from "views/Account/components/DropDownPlus";



var _ = require('lodash');

const styles = theme => ({
    root: {
        padding: theme.spacing(4)
    },
    tabs: {
        paddingLeft: 25
    }
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`wrapped-tabpanel-${index}`}
            aria-labelledby={`wrapped-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};


class ManageUITestsPage extends Component {
    state = {
        tabValue: 0,
        editMode: false,
        _id: null,
        name: '',
        description: '',
        tags: [],
        actions: [],
        options: {
            pages: [],
            actions: [],
            dataElements: [],
        },
        pageVsActionVsDataElements: {},
        testsTableData: [],
        testData: [],
    };

    async refreshTests() {
        let res = await fetch(`/api/tests`);
        let json = await res.json();
        this.setState({ testsTableData: json });
    }

    async loadPageVsActionVsDataElements() {
        let res = await fetch(`/api/tests/pageVsActionVsDataElements`);
        let json = await res.json();
        this.setState({ pageVsActionVsDataElements: json })
    }

    async componentDidMount() {
        this.loadPageVsActionVsDataElements();
        this.refreshTests();
    }

    CreateNewTest = () => {
        this.setState({
            tabValue: 0,
            editMode: true,
            _id: null,
            name: '',
            description: '',
            tags: [],
            actions: [],
            dataElements: [],
            testData: []
        })
    }

    handleUpdate = async () => {
        console.log('upsert!');
        let res = await fetch('/api/tests/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                _id: this.state._id,
                name: this.state.name,
                description: this.state.description,
                tags: this.state.tags,
                actions: this.state.actions,
                testData: this.state.testData
            })
        });
        let json = await res.json();
        if (json.status === 'success') {
            alert('Saved!');
            this.setState({
                _id: json.data._id
            })
        } else {
            alert('Something went wrong!');
        }
        this.refreshTests();

    }

    onRowAdd = async (newData) => {
        this.setState({
            actions: [...this.state.actions, {
                ...newData,
                _id: Date.now()
            }]
        });
    }

    onRowUpdate = async (newData, oldData) => {
        this.setState({
            actions: [...this.state.actions.filter(ele => ele._id !== oldData._id), newData]
        })
    }

    onRowDelete = async (oldData) => {
        this.setState({
            actions: this.state.actions.filter(ele => ele._id !== oldData._id)
        })
    }

    //--------- test data -------- //
    onRowAddTestData = async (newData) => {
        this.setState({
            testData: [...this.state.testData, {
                ...newData,
                _id: Date.now()
            }]
        });
    }

    onRowUpdateTestData = async (newData, oldData) => {
        this.setState({
            testData: [...this.state.testData.filter(ele => ele._id !== oldData._id), newData]
        })
    }

    onRowDeleteTestData = async (oldData) => {
        this.setState({
            testData: this.state.testData.filter(ele => ele._id !== oldData._id)
        })
    }


    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <Grid
                    container
                    spacing={4}
                >
                    <Grid item lg={12} md={12} xl={12} xs={12} >
                        {this.state.editMode &&
                            <Card
                            // {...rest}
                            // className={clsx(classes.root, className)}
                            >
                                <form
                                    autoComplete="off"
                                    noValidate
                                >
                                    <CardHeader
                                        avatar={
                                            <IconButton aria-label="delete" onClick={() => this.setState({ editMode: false })}>
                                                <ArrowBackRoundedIcon color="primary" />
                                            </IconButton>
                                        }
                                        title={this.state._id ? 'Update Test' : 'Create Test'}
                                    />
                                    <Divider />
                                    <CardContent>
                                        <Grid container spacing={0}>
                                            <Grid item xs={5} >
                                                <div style={{ paddingTop: 5 }}></div>
                                                <TextField
                                                    fullWidth
                                                    helperText="Please specify the test name"
                                                    label="Test Name"
                                                    margin="dense"
                                                    name="testName"
                                                    onChange={e => this.setState({ name: e.target.value })}
                                                    required
                                                    value={this.state.name}
                                                    variant="outlined"
                                                />
                                                <br /><br />

                                                <TextField
                                                    fullWidth
                                                    helperText="Please specify the test description"
                                                    id="outlined-multiline-static"
                                                    label="Test Description"
                                                    margin="dense"
                                                    multiline
                                                    rows="3"
                                                    variant="outlined"
                                                    onChange={e => this.setState({ description: e.target.value })}
                                                    required
                                                    value={this.state.description}
                                                />
                                                <br />
                                            </Grid>
                                            <Grid item xs={1} >

                                            </Grid>

                                            <Grid item xs={4} >
                                                <DropDownPlus
                                                    create
                                                    placeholder={'Tags'} label={'Tags'} multi={true}
                                                    options={[]}
                                                    values={this.state.tags ? this.state.tags : []}
                                                    onChange={v => { this.setState({ tags: v }, () => { /*this.refreshRuleNameVals();*/ }); }}
                                                />
                                                <br /><br />
                                                <TextField
                                                    fullWidth
                                                    helperText="Please specify user stories"
                                                    label="User Stories"
                                                    margin="dense"
                                                    name="userStories"
                                                    onChange={e => this.setState({ userStories: e.target.value })}
                                                    required
                                                    value={this.state.userStories}
                                                    variant="outlined"
                                                />

                                            </Grid>
                                        </Grid>


                                        <br /><br />

                                        <Grid item md={12} xs={12} >

                                            <Paper className={classes.tabs}>
                                                <Tabs value={this.state.tabValue} indicatorColor="primary" textColor="primary" onChange={(e, v) => this.setState({ tabValue: v })}>
                                                    <Tab label="Steps" />
                                                    <Tab label="Test Data(remove)" />
                                                </Tabs>
                                            </Paper>
                                            <TabPanel value={this.state.tabValue} index={0}>
                                                <MaterialTable
                                                    title=""
                                                    options={{ pageSize: 10, actionsColumnIndex: -1, search: false, }}
                                                    localization={{ header: { actions: '' }, }}
                                                    columns={[
                                                        // { title: '_id', field: '_id' },
                                                        {
                                                            title: 'Page', field: 'page',
                                                            lookup: Object.keys(this.state.pageVsActionVsDataElements).reduce((map, ele) => {
                                                                map[ele] = ele
                                                                return map;
                                                            }, {})
                                                        },
                                                        // {
                                                        // 	title: 'Action', field: 'action',
                                                        // 	lookup: this.state.options.actions.reduce((map, ele) => {
                                                        // 		map[ele] = ele
                                                        // 		return map;
                                                        // 	}, {})
                                                        // },
                                                        {
                                                            title: 'Action', field: 'action',
                                                            render: rowData => rowData.action,
                                                            editComponent: props => {
                                                                console.log('propsprops', props);
                                                                return <DropDownPlus
                                                                    placeholder={'Action'} label={'Action'} multi={false}
                                                                    options={(props.rowData.page) ? Object.keys(this.state.pageVsActionVsDataElements[props.rowData.page]) : []}
                                                                    values={props.value ? [props.value] : []}
                                                                    onChange={v => {
                                                                        props.onChange(v)
                                                                    }}
                                                                />
                                                            },
                                                        },
                                                        {
                                                            title: 'Data Elements', field: 'dataElement',
                                                            render: rowData => (rowData.dataElement) ? rowData.dataElement.join(', ') : '',
                                                            editComponent: props => {
                                                                console.log('propsprops', props);
                                                                return <DropDownPlus
                                                                    placeholder={'Data Elements'} label={'Data Elements'} multi={true}
                                                                    values={props.value ? props.value : []}
                                                                    options={(props.rowData.page && props.rowData.action && this.state.pageVsActionVsDataElements[props.rowData.page]
                                                                        && this.state.pageVsActionVsDataElements[props.rowData.page][props.rowData.action]) ?
                                                                        this.state.pageVsActionVsDataElements[props.rowData.page][props.rowData.action] : []}
                                                                    onChange={v => {
                                                                        props.onChange(v)
                                                                    }}
                                                                />
                                                            },
                                                        },
                                                    ]}
                                                    data={this.state.actions.sort((e1, e2) => e1._id > e2._id ? 1 : -1)}
                                                    editable={{
                                                        isEditable: () => true,
                                                        isDeletable: () => true,
                                                        onRowAdd: this.onRowAdd,
                                                        onRowUpdate: this.onRowUpdate,
                                                        onRowDelete: this.onRowDelete,
                                                    }}
                                                />

                                                < br />
                                                <Button style={{ float: 'right' }} color="primary" variant="contained"
                                                    onClick={() => this.setState({ tabValue: 1 })}
                                                > Next </Button>
                                                < br />

                                            </TabPanel>
                                            <TabPanel value={this.state.tabValue} index={1}>
                                                {/** Dynamic data table */}

                                                <MaterialTable
                                                    title={
                                                        <RadioGroup row aria-label="position" name="position" defaultValue="top" style={{ padding: 4 }}
                                                            onChange={e => {
                                                                console.log(e.target.value);
                                                                this.setState({
                                                                    env: e.target.value
                                                                })
                                                            }
                                                            }
                                                        >
                                                            <FormControlLabel
                                                                value="QA"
                                                                control={<Radio color="primary" />}
                                                                label="QA"
                                                                labelPlacement="end"
                                                            />

                                                            <FormControlLabel
                                                                value="UAT"
                                                                control={<Radio color="primary" />}
                                                                label="UAT"
                                                                labelPlacement="end"
                                                            />
                                                        </RadioGroup>
                                                    }
                                                    options={{
                                                        pageSize: 10,
                                                        actionsColumnIndex: -1, search: false
                                                    }}
                                                    localization={{
                                                        header: {
                                                            actions: ''
                                                        },
                                                    }}
                                                    columns={
                                                        (this.state.actions && this.state.actions.length > 0) ?
                                                            _.uniq(_.flatten(this.state.actions.map(ele => ele.dataElement))).map(ele =>
                                                            ({
                                                                title: ele, field: ele,
                                                            })
                                                            )
                                                            :
                                                            []
                                                    }
                                                    data={this.state.testData.sort((e1, e2) => e1._id > e2._id ? 1 : -1)}
                                                    editable={{
                                                        isEditable: () => true,
                                                        isDeletable: () => true,
                                                        onRowAdd: this.onRowAddTestData,
                                                        onRowUpdate: this.onRowUpdateTestData,
                                                        onRowDelete: this.onRowDeleteTestData,
                                                    }}
                                                />
                                                < br />
                                                <Button style={{ float: 'right' }} color="primary" variant="contained"
                                                    onClick={() => this.setState({ tabValue: 0 })}
                                                > Previous </Button>
                                                < br />
                                                {/** Dynamic data table */}
                                            </TabPanel>
                                        </Grid>
                                    </CardContent>
                                    <Divider />
                                    <CardActions >
                                        <Button
                                            disabled={(this.state.testData && this.state.testData.length > 0) ? false : true}
                                            color="primary"
                                            variant="contained"
                                            onClick={this.handleUpdate}
                                        >
                                            Save
                                        </Button>
                                    </CardActions>
                                </form>
                            </Card>

                        }


                        {!this.state.editMode &&
                            <Card>

                                <CardHeader
                                    //subheader="The information can be edited"
                                    title={'Tests'}
                                    onClick={this.CreateNewTest}
                                    action={
                                        <Button
                                            color="primary"
                                            size="small"
                                            variant="outlined"
                                        >
                                            New Test
                                        </Button>
                                    }
                                />
                                <Divider />
                                <CardContent>
                                    <Grid item md={12} xs={12} >
                                        <MaterialTable
                                            title=""
                                            options={{ actionsColumnIndex: -1, pageSize: 10 }}
                                            localization={{
                                                header: {
                                                    actions: ''
                                                },
                                            }}
                                            columns={[
                                                { title: 'Name', field: 'name' },
                                                { title: 'Description', field: 'description' },
                                                { title: 'Tags', field: 'tags', render: rowData => (rowData.tags) ? rowData.tags.join(', ') : '' },
                                            ]}
                                            data={this.state.testsTableData}
                                            editable={{}}
                                            onRowClick={((evt, selectedRow) => this.setState({
                                                _id: selectedRow._id,
                                                name: selectedRow.name,
                                                description: selectedRow.description,
                                                tags: selectedRow.tags,
                                                actions: selectedRow.actions,
                                                editMode: true,
                                                testData: selectedRow.testData,
                                            }, () => { window.scrollTo(0, 0); }))}
                                        />
                                    </Grid>
                                </CardContent>
                            </Card>
                        }
                    </Grid>
                </Grid>
            </div >
        );
    }
}

// const ManageUITestsPage = () => {
// 	const classes = useStyles();
// };

export default withStyles(styles)(ManageUITestsPage);

//export default ManageUITestsPage;