// import { makeStyles } from '@material-ui/core/styles';
// import dynamic from 'next/dynamic';
import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
// import { useSelector } from 'react-redux';
import breaks from 'remark-breaks';
import gfm from 'remark-gfm';
import CodeBlock from './CodeBlock';

// const useStyles = makeStyles((theme) => ({
//     problemIndexItem: {
//         flexGrow: 1,
//         borderRadius: 4,
//         //border: '1px solid #dadce0',
//         background: (theme.palette.type === 'dark') ? theme.palette.alternate.dark : theme.palette.grey[100],
//         //backgroundColor: '#f5f5f5',
//         cursor: 'pointer',
//     },
//     problemIndexItemSelected: {
//         backgroundColor: (theme.palette.type === 'light') ? '#d0cdfe' : theme.palette.grey[600],
//     }
// }))

// const CodeBlock = dynamic(() => import('../CodeBlock'), { ssr: false });

const OneMarkdown = props => {
    // const userObj = useSelector(state => state.user);
    const [renderers, setRenderers] = useState(null);
    const [codeMode, setCodeMode] = useState(null);

    useEffect(() => {
        setRenderers({ code: CodeBlock });
        setCodeMode('light');
    }, [])

    return (
        <ReactMarkdown className={`md-view  oc-theme-${codeMode} `}
            plugins={[gfm, breaks]}
            source={props.markdown || ''}
            renderers={renderers || {}}
        />
    )
};

export default OneMarkdown;