import {
	Button, Card, CardContent, CardHeader, Divider, Grid,
	IconButton, Paper, Tab, Tabs, TextField, Typography
} from '@material-ui/core';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import { makeStyles } from '@material-ui/styles';
import KeyValBuilder from 'components/KeyValBuilder';
import MaterialTable from 'material-table';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AntTab, AntTabs } from './components/Ants';
import { get, post, del } from './components/commons/common-components';
import { useHistory, useParams } from "react-router-dom";
import DropDownPlus from "views/Account/components/DropDownPlus";
import OneEditor from 'components/OneEditor';


const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(2)
	},
	pre: {
		padding: theme.spacing(2),
		overflowY: 'auto',
		maxHeight: 200
	},
	tabs: {
		paddingLeft: 25
	},
	cardHeader: {
		minHeight: theme.spacing(6.9)
	},
	addButton: {
		marginRight: theme.spacing(1.5)
	},
	rightPadding: {
		paddingRight: theme.spacing(1)
	},
	resultCard: {
		marginTop: theme.spacing(1.5),
		width: "100%",
		background: "#f2f2f2",
	}
}))

function jsonStringBeautify(inp) {
	if (inp && (inp.startsWith("{") || inp.startsWith("["))) {
		try {
			inp = JSON.parse(inp)
		} catch (e) {
			console.log(e);
		}
	}
	return inp;
}

const APICallPage = props => {
	const classes = useStyles();
	const history = useHistory();
	const token = useSelector(state => state.user.token);
	const [data, setData] = useState(null);
	let { id } = useParams();

	useEffect(() => {
		buildHome(id);
	}, [id])

	async function buildHome(id) {
		if (id) {
			let data = await get(`/api/tasks/apiCall/${id}`, token);
			data.body = jsonStringBeautify(data.body);
			data.response = jsonStringBeautify(data.response);
			setData(data);
		}
	}

	return (
		<div style={{ height: "100%" }}>
			<OneEditor
				//onRun={() => { this.handleOnRunClick() }}
				height={'100%'}
				value={JSON.stringify(data, null, 2)}
				mode={'groovy'}
				//theme={(this.props.loggedInUserObj.theme && this.props.loggedInUserObj.theme === 'dark') ? 'dark' : languages[this.state.codeObj.properties.language].theme}
				elevation={3}
			// onChange={updatedCode => {
			// 	setActionValue(updatedCode);
			// }}
			/>
		</div>
	)

}
export default APICallPage;
