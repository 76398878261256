import {
	Button, Card,


	CardActions, CardContent, CardHeader,


	Divider,
	Grid
} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Select from '@material-ui/core/Select';
import { withStyles } from "@material-ui/core/styles";
import MaterialTable from 'material-table';
import React, { Component } from "react";
import { connect } from 'react-redux';


const styles = theme => ({
	root: {
		padding: theme.spacing(2)
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
});


class RunTeststsPage extends Component {

	state = {
		env: '',
		browser: 'Chrome',
		testType: 'UI',
		rows: [],
		selectedRows: [],
		projectData: {}
	};

	async refreshTests() {
		let resRows = await fetch(`/api/tests`, { headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${this.props.userToken}` }, });
		let jsonRows = await resRows.json();
		jsonRows = jsonRows.filter(ele => ele.testType === this.state.testType.toLowerCase());
		this.setState({ rows: jsonRows });
	}

	async getProjectData() {
		console.log('getProjectData for' + this.props.project._id);
		let res = await fetch(`/api/projects/${this.props.project._id}`, { headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${this.props.userToken}` }, });
		let json = await res.json();
		console.log('json', json);
		this.setState({ projectData: json });
		if (json.environments.length > 0) {
			this.setState({ env: json.environments[0] });
		}
	}

	async componentDidMount() {
		this.refreshTests();
		this.getProjectData();
	}

	handleRun = async () => {
		console.log('stats', this.state);
		let ids = this.state.selectedRows.map(ele => ele._id).join(',');
		let res = await fetch(`/api/tasks/testTasks/${this.state.testType?.toLowerCase()}/${ids}?stage=${this.state.env}&browser=${this.state.browser}`, {
			method: 'GET',
			headers: {
				"Content-Type": 'application/json',
				"Authorization": `Bearer ${this.props.userToken}`
			}
		});
		let text = await res.text();
		alert(text);
	}

	render() {
		const { classes } = this.props;

		return (
			<div className={classes.root}>
				<Grid
					container
					spacing={4}
				>
					<Grid item lg={12} md={12} xl={12} xs={12} >
						<Card>
							<CardHeader
								//subheader="The information can be edited"
								title={'Run Tests'}
							/>
							<Divider />
							<CardContent>
								<Grid item md={12} xs={12} >
									<Grid item md={12} xs={12} >
										<br />
										<Grid container spacing={3} justifyContent="space-between"
										>
											<Grid item >
												<FormControl component="fieldset">
													<FormLabel component="legend">Environment</FormLabel>

													<RadioGroup row aria-label="position" name="position" defaultValue="top" style={{ padding: 4 }}
														value={this.state.env}
														onChange={e => {
															console.log(e.target.value);
															this.setState({
																env: e.target.value
															})
														}
														}
													>
														{(this.state.projectData?.environments || []).map((env, index) => {
															return (
																<FormControlLabel
																	key={env}
																	value={env}
																	control={<Radio color="primary" />}
																	label={env}
																	labelPlacement="end"
																/>
															)
														})}
													</RadioGroup>
												</FormControl>
											</Grid>
											<Grid item >
												<div style={{ display: 'flex' }}>
													<div >
														<FormControl className={classes.formControl} style={{ marginRight: 50 }}>
															<InputLabel id="demo-simple-select-label">Test Type</InputLabel>
															<Select
																labelId="demo-simple-select-label"
																id="demo-simple-select"
																value={this.state.testType}
																onChange={e => {
																	this.setState({ testType: e.target.value }, () => this.refreshTests());
																}}
															>
																<MenuItem value={'UI'}>UI</MenuItem>
																<MenuItem value={'API'}>API</MenuItem>
															</Select>
														</FormControl>
													</div>
													<div>
														<FormControl className={classes.formControl}>
															<InputLabel id="demo-simple-select-label">Browser</InputLabel>
															<Select
																labelId="demo-simple-select-label"
																id="demo-simple-select"
																value={this.state.browser}
																onChange={e => this.setState({ browser: e.target.value })}
															>
																<MenuItem value={'Chrome'}>Chrome</MenuItem>
																<MenuItem value={'Firefox'}>Firefox</MenuItem>
															</Select>
														</FormControl>
													</div>
												</div>
											</Grid>
										</Grid>
										<br /><br />
										<MaterialTable
											title="Select Tests"
											columns={[
												{ title: 'Name', field: 'name' },
												{ title: 'Description', field: 'description' },
												{ title: 'Tags', field: 'tags' },
												// {
												// 	title: 'Run', field: 'tags', render: rowData =>
												// 		<Button
												// 			color="primary"
												// 			variant="contained"
												// 			onClick={() => this.handleRun(rowData)}
												// 		>
												// 			Run
												// 		</Button>
												// },
											]}
											data={this.state.rows}
											options={{
												pageSize: 10,
												selection: true
											}}
											onSelectionChange={(rows) => this.setState({
												selectedRows: rows
											})}
											editable={{}}
											onRowClick={((evt, selectedRow) => this.setState({
												_id: selectedRow._id,
												name: selectedRow.name,
												description: selectedRow.description,
												tags: selectedRow.tags,
												actions: selectedRow.actions
											}, () => window.scrollTo(0, 0)))}
										/>
									</Grid>
								</Grid>
							</CardContent>
							<Divider />
							<CardActions >
								<Button
									color="primary"
									variant="contained"
									onClick={this.handleRun}
								>
									Run
								</Button>

							</CardActions>
						</Card>
					</Grid>
				</Grid>
			</div>
		);

	}
}

const mapStateToProps = state => ({
	userId: state.user._id,
	userToken: state.user.token,
	project: state.user.project
});

export default connect(mapStateToProps, {})(withStyles(styles)(RunTeststsPage));


