import React from 'react';
import {
	Button, Card, CardContent,
	Divider, Grid,
	TextField, Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import KeyValBuilder from 'components/KeyValBuilder';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { AntTab, AntTabs } from './components/Ants';


const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(2)
	},
	pre: {
		padding: theme.spacing(2),
		overflowY: 'auto',
		maxHeight: 200
	},
	tabs: {
		paddingLeft: 25
	},
	cardHeader: {
		minHeight: theme.spacing(6.9)
	},
	addButton: {
		marginRight: theme.spacing(1.5)
	},
	rightPadding: {
		paddingRight: theme.spacing(1)
	},
	resultCard: {
		marginTop: theme.spacing(1.5),
		width: "100%",
		background: "#f2f2f2",
	}
}))

const HomePage = props => {
	const classes = useStyles();
	const history = useHistory();
	const token = useSelector(state => state.user.token);
	const [values, setValues] = useState({
		domain: 'https://jsonplaceholder.typicode.com',
		url: '/todos'
	});
	const [testResult, setTestResult] = useState(null);
	const [requestTabIndex, setRequestTabIndex] = useState(0);

	async function handleTest() {
		let res = await fetch('/api/tasks/testOneTask', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				...values,
				"type": "api"
			})
		});
		let json = await res.json();
		//console.log(json)
		let data = json.response;
		if (data && data.trim().startsWith("{") || data.trim().startsWith("[")) { // json response
			let dataObj = JSON.parse(data);
			data = JSON.stringify(dataObj, null, 2);
		}
		//console.log(data)
		setTestResult({ status: json.responseCode, data });
	}

	return (
		<Card>
			<CardContent>
				<br />
				<Grid container direction="column" justifyContent="center" alignItems="center" >
					<Grid item xs={12} md={12} lg={12}>
						<Typography variant="h1" gutterBottom> Welcome to Medha </Typography>
					</Grid>
				</Grid>
				<br />
				<Grid container direction="row" justify="center" alignItems="center">
					<Grid item xs={12} md={10} lg={8}>
						<Grid container spacing={0}>
							<Grid item xs={12} md={12} lg={12} >
								<TextField fullWidth label="Domain" margin="dense" name="domain" required variant="outlined" type="text"
									value={values.domain || ''} onChange={e => setValues({ ...values, domain: e.target.value })}
								/>
							</Grid>
							<Grid container spacing={0}>
								<Grid item xs={12} md={2} lg={2} >
									<TextField fullWidth label="Method" className={classes.rightPadding} margin="dense" name="method" required select variant="outlined"
										SelectProps={{ native: true }}
										value={values.method || 'GET'}
										onChange={(e) => setValues({ ...values, method: e.target.value })}
									> {['GET', 'POST', 'PUT', 'DELETE'].map(option => <option key={option} value={option} > {option} </option>)}
									</TextField>
								</Grid>
								<Grid item xs={12} md={10} lg={10} >
									<TextField fullWidth label="Endpoint" margin="dense" name="url" required variant="outlined" type="text"
										value={values.url || ''} onChange={(e) => setValues({ ...values, url: e.target.value })}
									/>
								</Grid>
							</Grid>
							<Grid item xs={12} md={12} lg={12} >
								<br />
								<AntTabs value={requestTabIndex} onChange={(e, newVal) => setRequestTabIndex(newVal)} aria-label="ant example">
									<AntTab label="Headers" />
									<AntTab label="Query Parameters" />
									<AntTab label="Form Parameters" />
									<AntTab label="Body" />
								</AntTabs>
							</Grid>
							{requestTabIndex === 0 &&
								<KeyValBuilder rows={values.headers}
									handleUpdate={newRows => setValues({ ...values, headers: newRows })} />
							}
							{requestTabIndex === 1 &&
								<KeyValBuilder rows={values.queryParameters}
									handleUpdate={newRows => setValues({ ...values, queryParameters: newRows })} />
							}
							{requestTabIndex === 2 &&
								<KeyValBuilder rows={values.formParameters}
									handleUpdate={newRows => setValues({ ...values, formParameters: newRows })} />
							}
							{requestTabIndex === 3 &&
								<TextField fullWidth id="outlined-multiline-static-body" label="Request Body" name="body" margin="dense" multiline rows="15" variant="outlined"
									value={values.body || ''} onChange={(e) => setValues({ ...values, body: e.target.value })}
								/>
							}
							{testResult &&
								<Grid item xs={12} md={12} lg={12} >
									<br /> <Divider /> <br />
									<Typography variant="h5" gutterBottom> Response: </Typography>
									<Typography variant="body2" gutterBottom> {`Status Code: ${testResult.status}`} </Typography>
									<Card className={classes.resultCard}>
										<pre className={classes.pre}> {testResult.data} </pre>
									</Card>
								</Grid>
							}
							<Grid container>
								<Grid item xs={6} md={1} lg={1} >
									<br />
									<Button disabled={!values.domain || !values.url} color="primary" variant="contained"
										onClick={() => handleTest()}
									> Test </Button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	)

}
export default HomePage;
